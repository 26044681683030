import * as THREE from 'three';

const vertexShader = `
    precision highp sampler2D;
    precision highp usampler2D;
    out vec4 vColor;
    out vec3 vPosition;
    uniform vec2 resolution;
    uniform vec2 viewport;
    uniform float focal;
    
    uniform vec2 uXRange;
    uniform vec2 uYRange;
    uniform vec2 uZRange;

    attribute uint splatIndex;
    uniform sampler2D centerAndScaleTexture;
    uniform usampler2D covAndColorTexture;    

    vec2 unpackInt16(in uint value) {
        int v = int(value);
        int v0 = v >> 16;
        int v1 = (v & 0xFFFF);
        if((v & 0x8000) != 0)
        v1 |= 0xFFFF0000;
        return vec2(float(v1), float(v0));
    }

    void main () {
        ivec2 texSize = textureSize(centerAndScaleTexture, 0);
        ivec2 texPos = ivec2(splatIndex%uint(texSize.x), splatIndex/uint(texSize.x));
        vec4 centerAndScaleData = texelFetch(centerAndScaleTexture, texPos, 0);
        vec4 center = vec4(centerAndScaleData.xyz, 1);
        
        vec4 worldPos = modelMatrix * center; // Transform to world coordinates. The modelMatrix is provided by three.js
        if (worldPos.x < uXRange.x || worldPos.x > uXRange.y ||
            worldPos.y < uYRange.x || worldPos.y > uYRange.y ||
            worldPos.z < uZRange.x || worldPos.z > uZRange.y) {
            gl_Position = vec4(0.0, 0.0, 2.0, 1.0);
            return;
        }

        vec4 camspace = viewMatrix * worldPos; // Apply view matrix
        vec4 pos2d = projectionMatrix * camspace; // Apply projection matrix

        float bounds = 1.2 * pos2d.w;
        if (pos2d.z < -pos2d.w || pos2d.x < -bounds || pos2d.x > bounds
        || pos2d.y < -bounds || pos2d.y > bounds) {
        gl_Position = vec4(0.0, 0.0, 2.0, 1.0);
        return;
        }

        uvec4 covAndColorData = texelFetch(covAndColorTexture, texPos, 0);
        vec2 cov3D_M11_M12 = unpackInt16(covAndColorData.x) * centerAndScaleData.w;
        vec2 cov3D_M13_M22 = unpackInt16(covAndColorData.y) * centerAndScaleData.w;
        vec2 cov3D_M23_M33 = unpackInt16(covAndColorData.z) * centerAndScaleData.w;
        mat3 Vrk = mat3(
        cov3D_M11_M12.x, cov3D_M11_M12.y, cov3D_M13_M22.x,
        cov3D_M11_M12.y, cov3D_M13_M22.y, cov3D_M23_M33.x,
        cov3D_M13_M22.x, cov3D_M23_M33.x, cov3D_M23_M33.y
        );

        mat3 J = mat3(
        focal / camspace.z, 0., -(focal * camspace.x) / (camspace.z * camspace.z),
        0., focal / camspace.z, -(focal * camspace.y) / (camspace.z * camspace.z),
        0., 0., 0.
        );

        mat3 W = transpose(mat3(modelViewMatrix));
        mat3 T = W * J;
        mat3 cov = transpose(T) * Vrk * T;
        vec2 vCenter = vec2(pos2d) / pos2d.w;
        float diagonal1 = cov[0][0] + 0.3;
        float offDiagonal = cov[0][1];
        float diagonal2 = cov[1][1] + 0.3;
        float mid = 0.5 * (diagonal1 + diagonal2);
        float radius = length(vec2((diagonal1 - diagonal2) / 2.0, offDiagonal));
        float lambda1 = mid + radius;
        float lambda2 = max(mid - radius, 0.1);
        vec2 diagonalVector = normalize(vec2(offDiagonal, lambda1 - diagonal1));
        vec2 v1 = min(sqrt(2.0 * lambda1), 1024.0) * diagonalVector;
        vec2 v2 = min(sqrt(2.0 * lambda2), 1024.0) * vec2(diagonalVector.y, -diagonalVector.x);
        uint colorUint = covAndColorData.w;
        vColor = vec4(
        float(colorUint & uint(0xFF)) / 255.0,
        float((colorUint >> uint(8)) & uint(0xFF)) / 255.0,
        float((colorUint >> uint(16)) & uint(0xFF)) / 255.0,
        float(colorUint >> uint(24)) / 255.0
        );
        vPosition = position;

        gl_Position = vec4(
        vCenter 
            + position.x * v2 / viewport * 2.0 
            + position.y * v1 / viewport * 2.0, pos2d.z / pos2d.w, 1.0);
    }
`;

const fragmentShader = `
    #include <alphatest_pars_fragment>
    #include <alphahash_pars_fragment>
    in vec4 vColor;
    in vec3 vPosition;

    vec3 increaseSaturation(vec3 color, float intensity) {
        vec3 gray = vec3(dot(color, vec3(0.3, 0.59, 0.11))); // Convert to grayscale
        return mix(gray, color, intensity); // Increase saturation
    }

    void main () {
        // Compute the distance from the blob's center
        float distanceSquared = dot(vPosition.xy, vPosition.xy);
        
        // Discard fragments that are too far from the blob's center
        // if (distanceSquared > 4.0) discard;

        // Compute the Gaussian falloff for soft edges
        float falloff = exp(-distanceSquared);

        // Compute base alpha based on distance falloff
        float baseAlpha = falloff * vColor.a;
        
        // Increase color saturation (intensity can be adjusted, for example, 1.5)
        vec3 saturatedColor = increaseSaturation(vColor.rgb, 1.0);
        
        // Adjust alpha for smoother transition at the edges
        float adjustedAlpha = min(baseAlpha * 1.5, 1.0); // Increase alpha to reduce transparency
        
        vec4 diffuseColor = vec4(saturatedColor, adjustedAlpha);

        #include <alphatest_fragment>
        #include <alphahash_fragment>
        gl_FragColor = diffuseColor;

        #include <tonemapping_fragment>
        #include <${parseInt(THREE.REVISION.replace(/\D+/g, '')) >= 154 ? 'colorspace_fragment' : 'encodings_fragment'}>
    }
`;

export {
    vertexShader,
    fragmentShader
};