import React from 'react';
// import { Splat } from '@react-three/drei';
// import { Splat } from 'SplatShader/Splat_v1';
import { Splat } from 'SplatShader/Splat';

const SplatRenderer = ({
    config, backgroundColor='rgba(0, 0, 0, 0)', pixelRatio = 1.0,
    loading, handleLoading
}) => {
    const modelUrl = config.url;
    const modelScale = config.scale;
    const modelRotation = [config.rotation[0], config.rotation[1], config.rotation[2]];
    const modelPosition = [config.center.x, config.center.y, config.center.z];

    const range = config.interaction === 'FirstPerson' ? { x: [-999, 999], y: [-999, 999], z: [-999, 999] } : config.range;

    const highResolution = config.highResolution ? config.highResolution : false;

    return (
        <Splat
            src={modelUrl}
            scale={modelScale}
            rotation={modelRotation}
            position={modelPosition}
            pixelRatio={pixelRatio}
            range={range}
            loading={loading}
            handleLoading={handleLoading}
            highResolution={highResolution}
            backgroundColor={backgroundColor}
        />
    );
};

export {
    SplatRenderer
};
