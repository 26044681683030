import React, { useEffect, useRef, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Canvas } from '@react-three/fiber';
import { isMobile, isIPad13 } from 'react-device-detect';
import { CameraControls, MobileController } from './Movement';
import Mask from './Mask';
import WaterMark from './WaterMark';
import CameraList from './CameraList';
import Info from './Info';
import FeatureModal from './FeatureModal';
import { SplatRenderer } from './SplatRenderer';
import VirtualTourGuide from './VirtualTourGuide';
import UserGuide from './UserGuide';
import { useNavigate } from 'react-router-dom';
import userControllerInstance from '../../controllers/UserController';
import { Perf } from 'r3f-perf';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoIcon from '@mui/icons-material/Info';
import PlaceIcon from '@mui/icons-material/Place';
import ForumIcon from '@mui/icons-material/Forum';

import { XR, createXRStore } from '@react-three/xr'
const store = createXRStore()

const InteractiveModel = ({ config, openPortal, destination }) => {
  const [currentCamera, setCurrentCamera] = useState(0);
  const [loading, setLoading] = useState(true);
  const [percent, setPercent] = useState(0);
  const controllerMove = useRef(false);
  const controllerRotate = useRef(false);
  const navigate = useNavigate();
  const [cameraData, setCameraData] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [userId, setUserId] = useState(null);
  const [currentFeature, setCurrentFeature] = useState(null);
  const [featureModalExpanded, setFeatureModalExpanded] = useState(false);

  // Sidebar visibility states
  const [showUserGuideSidebar, setShowUserGuideSidebar] = useState(!isMobile && !isIPad13);
  const [showInfoSidebar, setShowInfoSidebar] = useState(false);
  const [showTourGuideSidebar, setShowTourGuideSidebar] = useState(false);
  const [showViewPointSidebar, setShowViewPointSidebar] = useState(false);

  // Check if the user has the Virtual Tour Guide feature enabled
  const [isVirtualTourGuideEnabled, setIsVirtualTourGuideEnabled] = useState(false);
  const [isFeatureTagEnabled, setIsFeatureTagEnabled] = useState(false);

  const isMobileDevice = isMobile || isIPad13;
  const iconButtonMargin = 20; // Margin from the edge

  const styles = {
    progressBarContainer: {
      height: '20px',
      width: '300px',
      backgroundColor: '#ddd',
      borderRadius: '4px',
      overflow: 'hidden',
      marginBottom: '20px',
      position: 'relative',
    },
    progressBar: (percentage) => ({
      height: '100%',
      width: `${percentage}%`,
      backgroundColor: '#3366FF',
    }),
    progressText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: '#000',
      fontSize: isMobile ? '10px' : '14px',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: 'bold',
    },
    sidebarContainer: {
      position: 'fixed',
      top: 0,
      right: 0,
      height: '100%',
      zIndex: 2000,
      display: 'flex',
      flexDirection: 'row',
    },
    iconButtonsContainer: (isAnySidebarOpen, sidebarWidth) => ({
      position: 'fixed',
      top: '20px',
      right: isAnySidebarOpen ? `${sidebarWidth + iconButtonMargin}px` : `${iconButtonMargin}px`,
      zIndex: 2001,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    }),
    sidebar: (sidebarWidth, showSidebar) => ({
      width: `${sidebarWidth}px`,
      backgroundColor: '#333',
      color: '#fff',
      transition: 'transform 0.5s ease-in-out',
      transform: showSidebar ? 'translateX(0)' : 'translateX(100%)',
    }),
    iconButton: (selected) => ({
      width: selected? '48px' : '40px',
      height: selected? '48px' : '40px',
      color: selected? 'white' : 'black',
      backgroundColor: selected? 'rgba(0, 0, 0, 0.5)': 'rgba(255, 255, 255, 1)',
    }),
    arrowBack: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      width: '48px',
      height: '48px',
      color: 'black',
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      zIndex: 2001,
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
        const isVirtualTourGuideEnabled = await userControllerInstance.checkUserFeatureFlag(
          user.uid,
          'virtualTourGuide'
        );
        const isFeatureTagEnabled = await userControllerInstance.checkUserFeatureFlag(
          user.uid,
          'featureTag'
        );
        switch (true) {
          case isVirtualTourGuideEnabled:
            setIsVirtualTourGuideEnabled(true);
            break;
          case isFeatureTagEnabled:
            setIsFeatureTagEnabled(true);
            break;
          default:
            break;
        }
      }
    };
    fetchData();
  }, [userId]);

  const handleSidebarToggle = (type) => {
    setShowUserGuideSidebar(type === 'userGuide');
    setShowInfoSidebar(type === 'info');
    setShowTourGuideSidebar(type === 'tourGuide');
    setShowViewPointSidebar(type === 'viewPoint');
  };


  // Handle camera data coming from the VirtualTourGuide
  const handleCameraData = (camera) => {
    setCameraData(camera);
  };

  // Pointer lock
  const pointerLockRef = useRef(false);

  const glRef = useRef(null);
  const pixelRatioRef = useRef(1.0);

  const isIframe = window.self !== window.top;

  // Sidebar width adjustments
  const [sidebarWidth, setSidebarWidth] = useState(400);

  useEffect(() => {
    if (isMobileDevice) {
      if ((2 * window.innerWidth) / 3 > 400) {
        setSidebarWidth(400);
      } else {
        setSidebarWidth((2 * window.innerWidth) / 3);
      }
    } else {
      setSidebarWidth(400);
    }
  }, [isMobileDevice]);

  useEffect(() => {
    const handlePointerLockChange = () => {
      if (document.pointerLockElement) {
        pointerLockRef.current = true;
      } else {
        pointerLockRef.current = false;
      }
    };

    document.addEventListener('pointerlockchange', handlePointerLockChange);
    return () => {
      document.removeEventListener('pointerlockchange', handlePointerLockChange);
    };
  }, []);

  const handleLoading = (percent) => {
    if (percent) {
      setPercent(percent.toFixed());
      if (percent === 100) {
        setLoading(false);
      }
    }
  };

  const handleToggleFeatureExpand = () => {
    setFeatureModalExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (destination && !loading && !isMobile && !isIPad13) {
      document.body.requestPointerLock();
      handleSidebarToggle('');
    }
  }, [percent, loading]);

  const handleFeatureChange = (feature) => {
    setCurrentFeature(feature);
    setFeatureModalExpanded(false); // Reset expansion when feature changes
  };

  const isAnySidebarOpen =
    showUserGuideSidebar || showInfoSidebar || showTourGuideSidebar || showViewPointSidebar;

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100dvh',
        width: '100vw',
        margin: '0',
        top: '0',
        left: '0',
        backgroundColor: '#fff',
        color: '#fff',
      }}
    >
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div style={styles.progressBarContainer}>
            <div style={styles.progressBar(percent)}></div>
            <div style={styles.progressText}>{percent}%</div>
          </div>
        </div>
      )}

      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Canvas
          style={{ background: 'black' }}
          onCreated={({ gl }) => {
            glRef.current = gl;
            gl.setPixelRatio(pixelRatioRef.current);
          }}
        >
          <XR store={store}>
            <SplatRenderer
              config={config}
              pixelRatio={pixelRatioRef.current}
              loading={loading}
              handleLoading={handleLoading}
            />

            <CameraControls
              config={config}
              currentCamera={currentCamera}
              pointerLockRef={!isMobile && !isIPad13 ? pointerLockRef : { current: true }}
              controllerMove={controllerMove}
              controllerRotate={controllerRotate}
              newCameraData={cameraData}
              openPortal={openPortal}
              destination={destination}
              onFeatureChange={handleFeatureChange}
              onToggleFeatureExpand={handleToggleFeatureExpand}
            />

            {/* <Perf /> */}
          </XR>
        </Canvas>
      </div>

      {(isMobile || isIPad13) && !loading && (
        <MobileController
          controllerMove={controllerMove}
          controllerRotate={controllerRotate}
          isAnySidebarOpen={isAnySidebarOpen}
        />
      )}

      {!isMobile && !isIPad13 && !isIframe && (
        <IconButton
          color="primary"
          style={styles.arrowBack}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      )}

      {/* <button
        style={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          zIndex: 2001,
          padding: '10px',
          backgroundColor: '#fff',
          color: '#000',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        onClick={() => store.enterAR()}
      >Enter AR</button> */}

      {/* Feature Modal */}
      {!loading && (
        <FeatureModal
          currentFeature={currentFeature}
          feature={currentFeature}
          expanded={featureModalExpanded}
          handleToggleFeatureExpand={handleToggleFeatureExpand}
        />
      )}

      {!isMobile && !isIPad13 && !loading && (
        <Mask
          isAnySidebarOpen={isAnySidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
        />
      )}
      {!loading && <WaterMark />}

      {/* Sidebar Container */}
      <div style={styles.sidebarContainer}>
        {/* Icon Buttons */}
        <div style={styles.iconButtonsContainer(isAnySidebarOpen, sidebarWidth)}>
          {/* Help Icon Button */}
          {!isMobile && !isIPad13 && <IconButton
            color="primary"
            style={styles.iconButton(showUserGuideSidebar)}
            onClick={() => handleSidebarToggle('userGuide')}
            aria-label="Toggle User Guide Sidebar"
          >
            <QuestionMarkIcon />
          </IconButton>}

          {/* Info Icon Button */}
          <IconButton
            color="primary"
            style={styles.iconButton(showInfoSidebar)}
            onClick={() => handleSidebarToggle('info')}
            aria-label="Toggle Info Sidebar"
          >
            <InfoIcon />
          </IconButton>

          {/* View Point Icon Button */}
          <IconButton
            color="primary"
            style={styles.iconButton(showViewPointSidebar)}
            onClick={() => handleSidebarToggle('viewPoint')}
            aria-label="Toggle View Point Sidebar"
          >
            <PlaceIcon />
          </IconButton>

          {/* Virtual Tour Guide Icon Button */}
          {isVirtualTourGuideEnabled && (
            <IconButton
              color="primary"
              style={styles.iconButton(showTourGuideSidebar)}
              onClick={() => handleSidebarToggle('tourGuide')}
              aria-label="Toggle Virtual Tour Guide Sidebar"
            >
              <ForumIcon />
            </IconButton>
          )}
        </div>

        {/* Sidebars */}
        {!isMobile && !isIPad13 && showUserGuideSidebar && (
          <div style={styles.sidebar(sidebarWidth, showUserGuideSidebar)}>
            <UserGuide onClose={() => setShowUserGuideSidebar(false)} />
          </div>
        )}

        {showInfoSidebar && (
          <div style={styles.sidebar(sidebarWidth, showInfoSidebar)}>
            <Info config={config} handleShowDescription={() => setShowInfoSidebar(false)} />
          </div>
        )}

        {showTourGuideSidebar && (
          <div style={styles.sidebar(sidebarWidth, showTourGuideSidebar)}>
            <VirtualTourGuide
              onCameraData={handleCameraData}
              chatHistory={chatHistory}
              setChatHistory={setChatHistory}
              onClose={() => setShowTourGuideSidebar(false)}
            />
          </div>
        )}

        {showViewPointSidebar && (
          <div style={styles.sidebar(sidebarWidth, showViewPointSidebar)}>
            <CameraList
              config={config}
              currentCamera={currentCamera}
              setCurrentCamera={setCurrentCamera}
              onClose={() => setShowViewPointSidebar(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InteractiveModel;
