import React, { useEffect, useRef, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

import { Joystick, JoystickShape } from 'react-joystick-component';
import baseImage from '../../asset/base.png';
import stickImage from '../../asset/stick.png';

import { isPointTouchingLines, slideAlongLine } from '../../Utils/Math';

const CameraControls = ({
  config,
  currentCamera,
  pointerLockRef,
  controllerMove,
  controllerRotate,
  newCameraData,
  openPortal,
  destination,
  onFeatureChange,
  onToggleFeatureExpand,
}) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  // add a listener to detect the orientation of the device
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      });
    };
  }, []);

  const { camera } = useThree();
  camera.rotation.order = 'YXZ';

  // Movement state refs
  const moveForward = useRef(false);
  const moveBackward = useRef(false);
  const moveLeft = useRef(false);
  const moveRight = useRef(false);
  const moveUp = useRef(false);
  const moveDown = useRef(false);
  const run = useRef(false);
  const squat = useRef(false); // New ref for squatting

  // squat
  const squatSpeed = 10; // Adjust speed
  const squatHeight = 0.5;

  // Jumping and gravity state refs
  const isJumping = useRef(false);
  const verticalVelocity = useRef(0);
  const jumpStrength = 2; // Adjust as needed
  const gravity = -9.81; // Gravity acceleration

  // Initial height ref
  const initialHeight = useRef(0);

  // Physics state refs
  const velocity = useRef(new THREE.Vector3());
  const direction = new THREE.Vector3();
  const bobbingTime = useRef(0);

  // Vertical offsets
  const squatOffset = useRef(0); // Offset for squatting
  const jumpOffset = useRef(0);  // Offset for jumping

  // Mouse movement
  const mouseMove = useRef({ x: 0, y: 0 });

  // Constants
  const cameraMoveSpeed = 1;
  const cameraRotateSpeed = 20;
  const controllerMoveSpeed = 3.5;
  const controllerRotateSpeed = (width > height) ? 3.0 : 5.0;
  const bobbingAmount = 0.005; // Bobbing height
  const bobbingSpeed = 6; // Bobbing speed

  const features = config.feature || [];
  const currentFeatureRef = useRef(null);

  const setCamera = (cameraConfig) => {
    camera.position.set(
      cameraConfig.position.x,
      cameraConfig.position.y,
      cameraConfig.position.z
    );

    // Set the initial height
    initialHeight.current = cameraConfig.position.y;

    camera.lookAt(
      cameraConfig.lookAt.x,
      cameraConfig.lookAt.y,
      cameraConfig.lookAt.z
    );

    camera.fov = config.fov;
    camera.updateProjectionMatrix();
  };

  useEffect(() => {
    if (config) {
      setCamera(config.cameraList[currentCamera]);
    }
  }, [currentCamera]);

  // Trigger the camera to move to the destination only once
  useEffect(() => {
    if (destination) {
      console.log("set camera to destination");
      setCamera(destination.camera);
    } else {
      console.log("set camera to config");
      setCamera(config.cameraList[currentCamera]);
    }
  }, []);

  const handleKeyDown = (event) => {
    switch (event.code) {
      case 'KeyW':
      case 'ArrowUp':
        moveForward.current = true;
        break;
      case 'KeyS':
      case 'ArrowDown':
        moveBackward.current = true;
        break;
      case 'KeyA':
      case 'ArrowLeft':
        moveLeft.current = true;
        break;
      case 'KeyD':
      case 'ArrowRight':
        moveRight.current = true;
        break;
      case 'KeyR':
        moveUp.current = true;
        break;
      case 'KeyF':
        moveDown.current = true;
        break;
      case 'Space':
        if (squat.current) {
          squat.current = false; // Stop squatting
        } else {
          if (!isJumping.current) {
            verticalVelocity.current = jumpStrength; // Jump strength
            isJumping.current = true;
          }
        }
        break;
      case 'ShiftLeft':
        run.current = true;
        break;
      case 'KeyC':
        squat.current = !squat.current; // Start squatting
        break;
      case 'KeyE':
        if (currentFeatureRef.current) {
          onToggleFeatureExpand();
        }
        break;
      default:
        break;
    }
  };

  const handleKeyUp = (event) => {
    switch (event.code) {
      case 'KeyW':
      case 'ArrowUp':
        moveForward.current = false;
        break;
      case 'KeyS':
      case 'ArrowDown':
        moveBackward.current = false;
        break;
      case 'KeyA':
      case 'ArrowLeft':
        moveLeft.current = false;
        break;
      case 'KeyD':
      case 'ArrowRight':
        moveRight.current = false;
        break;
      case 'KeyR':
        moveUp.current = false;
        break;
      case 'KeyF':
        moveDown.current = false;
        break;
      case 'ShiftLeft':
        run.current = false;
        break;
      case 'KeyZ':
        squat.current = false; // Stop squatting
        break;
      default:
        break;
    }
  };

  const handleMouseMove = (event) => {
    mouseMove.current.x = event.movementX || 0;
    mouseMove.current.y = event.movementY || 0;
  };

  const handleMouseStop = () => {
    mouseMove.current.x = 0;
    mouseMove.current.y = 0;
  };

  const walls = config.wall;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []); // Empty dependency array to run only once

  useFrame((state, delta) => {
    camera.getWorldDirection(direction);
    direction.y = 0;
    direction.normalize();

    const right = new THREE.Vector3();
    right.crossVectors(camera.up, direction).normalize();

    const acceleration = run.current ? 30 : 15;

    const currentCameraMoveSpeed = cameraMoveSpeed * delta;
    const currentCameraRotateSpeed = cameraRotateSpeed * delta;

    const currentControllerMoveSpeed = controllerMoveSpeed * delta;
    const currentControllerRotateSpeed = controllerRotateSpeed * delta;

    if (pointerLockRef.current) {
      // Input handling
      const moveDirection = new THREE.Vector3();
      if (moveForward.current) moveDirection.add(direction);
      if (moveBackward.current) moveDirection.sub(direction);
      if (moveLeft.current) moveDirection.add(right);
      if (moveRight.current) moveDirection.sub(right);
      if (moveUp.current) moveDirection.y += currentCameraMoveSpeed;
      if (moveDown.current) moveDirection.y -= currentCameraMoveSpeed;

      // Add controllerMove input
      if (controllerMove.current) {
        moveDirection.addScaledVector(direction, controllerMove.current.y * currentControllerMoveSpeed);
        moveDirection.addScaledVector(right, -controllerMove.current.x * currentControllerMoveSpeed);
      }

      moveDirection.normalize();

      // Apply acceleration
      velocity.current.addScaledVector(moveDirection, acceleration * delta);

      // Apply friction
      const friction = 10;
      velocity.current.multiplyScalar(1 - friction * delta);

      // Update squat offset
      const desiredSquatOffset = squat.current ? -squatHeight : 0; // Adjust squat amount
      squatOffset.current += (desiredSquatOffset - squatOffset.current) * delta * squatSpeed;

      // Apply gravity only when jumping
      if (isJumping.current) {
        verticalVelocity.current += gravity * delta;
        jumpOffset.current += verticalVelocity.current * delta;

        // Check if the player has landed
        if (jumpOffset.current <= 0) {
          jumpOffset.current = 0;
          verticalVelocity.current = 0;
          isJumping.current = false;
        }
      } else {
        jumpOffset.current = 0;
      }

      // Head bobbing
      let bobbingOffset = 0;
      const isMoving = velocity.current.length() > 0.1;
      if (isMoving) {
        bobbingTime.current += delta * bobbingSpeed;
        bobbingOffset = Math.sin(bobbingTime.current * 2) * bobbingAmount;
      } else {
        bobbingTime.current = 0;
        bobbingOffset = 0;
      }

      // Total vertical offset
      const totalVerticalOffset = squatOffset.current + jumpOffset.current + bobbingOffset;

      // Update camera position
      // Vertical movement
      const newHeight = initialHeight.current + moveDirection.y * currentCameraMoveSpeed;
      if (newHeight > squatHeight) {
        initialHeight.current = newHeight;
      }

      // Horizontal movement (with collision detection)
      const moveDelta = velocity.current.clone().multiplyScalar(delta);

      const startPosition = [camera.position.x, camera.position.z];
      const potentialPosition = [camera.position.x + moveDelta.x, camera.position.z + moveDelta.z];

      const nextPosition = walls ? slideAlongLine(startPosition, potentialPosition, walls) : potentialPosition;

      camera.position.x = nextPosition[0];
      camera.position.z = nextPosition[1];

      // Update camera's vertical position
      camera.position.y = initialHeight.current + totalVerticalOffset;

      // Adjust FOV for running
      const targetFOV = run.current ? 70 : 60;
      camera.fov += (targetFOV - camera.fov) * delta * 5;
      camera.updateProjectionMatrix();

      // Recalculate maxRotationX based on the current FOV
      const maxRotationDegrees = 80; // Allow up to ±85 degrees of vertical rotation
      const maxRotationX = THREE.MathUtils.degToRad(maxRotationDegrees);


      // Mouse movement
      if (mouseMove.current.x !== 0 || mouseMove.current.y !== 0) {
        if (destination) {
          destination = null;
        } else {
          camera.rotation.x -= currentCameraRotateSpeed * mouseMove.current.y * 0.002;
          camera.rotation.y -= currentCameraRotateSpeed * mouseMove.current.x * 0.002;
          handleMouseStop();
        }
      }

      // Controller rotation
      if (controllerRotate.current) {
        camera.rotation.x += currentCameraRotateSpeed * currentControllerRotateSpeed * controllerRotate.current.y;
        camera.rotation.y -= currentCameraRotateSpeed * currentControllerRotateSpeed * controllerRotate.current.x;
        // camera.rotation.z += currentCameraRotateSpeed * currentControllerRotateSpeed * controllerRotate.current.z;
      }

      // Clamp the vertical rotation to limit the view angle
      camera.rotation.x = Math.max(-maxRotationX, Math.min(maxRotationX, camera.rotation.x));

      // Handle portals
      if (config.portal) {
        for (let index in config.portal) {
          const portal = config.portal[index];
          if (
            isPointTouchingLines(
              [camera.position.x, camera.position.z],
              [portal.position]
            )
          ) {
            console.log('open portal');
            openPortal(portal);
            break;
          }
        }
      }

      // Feature detection based on screen position
      const threshold = 0.2; // Adjust as needed (0.1 corresponds to 10% of the screen from the center)
      const cameraDistanceThreshold = 1.5; // Adjust as needed
      let closestFeature = null;
      let minDistance = 0;

      features.forEach((feature) => {
        const featurePosition = new THREE.Vector3(
          feature.position.x,
          feature.position.y,
          feature.position.z
        );

        // calculate direction from camera to feature
        const direction = featurePosition.clone().sub(camera.position).normalize();

        // calculate direction from camera to camera lookAt
        const lookAtDirection = camera.getWorldDirection(new THREE.Vector3()).normalize();

        // calculate angle between direction and lookAtDirection
        const angle = direction.angleTo(lookAtDirection);

        if (angle < Math.PI / 2) {
          // Project feature position to normalized device coordinates (NDC)
          const vector = featurePosition.clone().project(camera);

          // Check if the feature is within the threshold around the center (0, 0)
          const distanceFromCenter = Math.sqrt(vector.x * vector.x + vector.y * vector.y);

          // check if the feature is within the threshold around camera
          const distanceFromCamera = camera.position.distanceTo(featurePosition);

          if (
            distanceFromCenter > minDistance &&
            distanceFromCamera < cameraDistanceThreshold
          ) {
            if (distanceFromCenter < threshold * (1 + 2 * (cameraDistanceThreshold - distanceFromCamera) / cameraDistanceThreshold)) {
              closestFeature = feature;
              minDistance = distanceFromCenter;
            }
          }
        }
      });

      if (closestFeature !== currentFeatureRef.current) {
        currentFeatureRef.current = closestFeature;
        onFeatureChange(closestFeature);
      }

      if (!closestFeature && currentFeatureRef.current) {
        currentFeatureRef.current = null;
        onFeatureChange(null);
      }
    }
  });

  const prevCameraDataRef = useRef(null);

  useEffect(() => {
    if (newCameraData && newCameraData !== prevCameraDataRef.current) {
      // Update camera position
      camera.position.set(
        newCameraData.position.x,
        newCameraData.position.y,
        newCameraData.position.z
      );
      // Update initial height
      initialHeight.current = newCameraData.position.y;
      // Update camera lookAt
      camera.lookAt(
        newCameraData.lookAt.x,
        newCameraData.lookAt.y,
        newCameraData.lookAt.z
      );
      // Ensure the camera updates immediately
      camera.updateProjectionMatrix();
      prevCameraDataRef.current = newCameraData;
    }
  }, [newCameraData, camera]);

  return null;
};


const MoveController = ({ controllerMove }) => {
  const handleMove = (move) => {
    controllerMove.current = { x: move.x, y: move.y };
  };

  const handleStop = () => {
    controllerMove.current = null;
  };

  return (
    <Joystick
      size={100}
      baseShape='square'
      baseColor='white'
      stickImage={stickImage}
      baseImage={baseImage}
      move={handleMove}
      stop={handleStop}
    />
  );
};

const RotateXYController = ({ controllerRotate }) => {
  const handleMove = (event) => {
    controllerRotate.current = { x: event.x, y: event.y, z: 0 };
  };

  const handleStop = () => {
    controllerRotate.current = null;
  };

  return (
    <Joystick
      size={100}
      baseShape='square'
      baseColor='white'
      stickImage={stickImage}
      baseImage={baseImage}
      move={handleMove}
      stop={handleStop}
    />
  );
};

const RotateZController = ({ controllerRotate }) => {
  const handleMove = (event) => {
    controllerRotate.current = true;
    controllerRotate.x = 0;
    controllerRotate.y = 0;
    controllerRotate.z = event.x;
  };

  const handleStop = () => {
    controllerRotate.current = false;
  };

  return (
    <Joystick
      size={100}
      baseColor="black"
      stickColor="white"
      controlPlaneShape={JoystickShape.AxisX}
      move={handleMove}
      stop={handleStop}
    />
  );
};

const MoveWithRotateController = ({ controllerMove, controllerRotate }) => {
  const handleMove = (move) => {
    if (Math.abs(move.y) > Math.abs(move.x)) {
      controllerMove.current = { x: 0, y: move.y, z: 0 };
    } else {
      controllerRotate.current = { x: move.x, y: 0, z: 0 };
    }
  };

  const handleStop = () => {
    controllerMove.current = null;
    controllerRotate.current = null;
  };

  return (
    <Joystick
      size={100}
      baseShape='square'
      baseColor='white'
      stickImage={stickImage}
      baseImage={baseImage}
      move={handleMove}
      stop={handleStop}
    />
  );
};

const MobileController = ({ controllerMove, controllerRotate, isAnySidebarOpen, moveUp, moveDown }) => {
  const showRotationZ = false;

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  // add a listener to detect the orientation of the device
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      });
    };
  }, []);

  // swipe left and right
  const [touchStart, setTouchStart] = useState(null)

  const onTouchStart = (e) => {
    setTouchStart(e.targetTouches[0]);
  };

  const rotateRate = 0.01;
  const onTouchMove = (e) => {
    controllerRotate.current = {
      x: 0.7 * rotateRate * (e.targetTouches[0].clientX - touchStart.clientX),
      y: 0.3 * rotateRate * -(e.targetTouches[0].clientY - touchStart.clientY),
    };
  };

  const onTouchEnd = () => {
    controllerRotate.current = null;
    setTouchStart(null);
  };

  const HorizontalTwoSticks = (
    <div>
      <div style={{
        position: 'absolute',
        padding: '20px',
        borderRadius: '5px',
        bottom: '10%',
        left: '10%',
      }}>
        <MoveController controllerMove={controllerMove} />
      </div>
      <div style={{
        position: 'absolute',
        padding: '20px',
        borderRadius: '5px',
        bottom: '10%',
        right: '10%',
      }}>
        <RotateXYController controllerRotate={controllerRotate} />
      </div>
    </div>
  );

  const OneStick = (
    <div style={{
      position: 'absolute',
      padding: '20px',
      borderRadius: '5px',
      bottom: '10%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <MoveWithRotateController
        controllerMove={controllerMove}
        controllerRotate={controllerRotate}
      />
    </div>
  );

  const VerticalTwoSticks = (
    <div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        controllerRotate={controllerRotate}
      />

      <div style={{
        position: 'absolute',
        // padding: '20px',
        // borderRadius: '5px',
        bottom: '7%',
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <MoveController controllerMove={controllerMove} />
      </div>
    </div>
  );

  if (!isAnySidebarOpen) {
    if (width > height) {
      return HorizontalTwoSticks;
    } else {
      return VerticalTwoSticks;
    }
  } else {
    return null;
  }
};

export {
  CameraControls,
  MobileController
};
