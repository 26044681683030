import React, { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const styles = {
    helpIcon: {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        borderRadius: '50%',
        fontSize: '20px',
        cursor: 'pointer',
    },
    rangeContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 0',
        gap: '10px',
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#fff',
        margin: '15px 0',
    },
    title: {
        fontFamily: '"Poppins", sans-serif',
        userSelect: 'none',
        transition: 'color 0.3s',
        margin: 0,
    },
    description: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: '16px',
        color: '#FFFFFFB2',
        margin: '10px 0',
    },
    inputGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
    },
    inputField: {
        width: '30%',
    },
    separator: {
        fontSize: '14px',
        color: '#666',
    },
    rangeInput: {
        width: '100%',
    },
    rangeLabel: {
        fontFamily: '"Poppins", sans-serif',
        fontSize: '16px',
        color: '#fff',
        width: '5%',
    },
    checkbox: {
        width: '20px',
        height: '20px',
    },
};

const RangeController = ({
    range, handleSetRange,
    applyRange, setApplyRange,
}) => {
    const rangeStep = 0.1;
    const [showDescription, setShowDescription] = useState(false);

    const handleShowDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <div style={styles.rangeContainer}>
            <label style={styles.label}>
                <h2 style={styles.title}>Crop</h2>
                <HelpOutlineIcon style={styles.helpIcon} onClick={handleShowDescription} />
            </label>

            {showDescription && (
                <p style={styles.description}>
                    Drag the slide bar to modify the model’s visible range. This will ensure the model displays at the right level to show details of the interior.
                </p>
            )}

            <div style={styles.inputGroup}>
                <label style={styles.rangeLabel}>X:</label>
                <input
                    type="number"
                    value={range.x[0]}
                    step={rangeStep}
                    onChange={(e) => handleSetRange({ ...range, x: [parseFloat(e.target.value), range.x[1]] })}
                    style={styles.inputField}
                    placeholder="Min value"
                />
                <span style={styles.separator}>-</span>
                <input
                    type="number"
                    value={range.x[1]}
                    step={rangeStep}
                    onChange={(e) => handleSetRange({ ...range, x: [range.x[0], parseFloat(e.target.value)] })}
                    style={styles.inputField}
                    placeholder="Max value"
                />
                <br />
                <input
                    type="checkbox"
                    checked={applyRange.x}
                    onChange={(e) => {setApplyRange({ ...applyRange, x: e.target.checked })}}
                    style={styles.checkbox}
                />
            </div>

            <div style={styles.inputGroup}>
                <label style={styles.rangeLabel}>Y:</label>
                <input
                    type="number"
                    value={range.y[0]}
                    step={rangeStep}
                    onChange={(e) => handleSetRange({ ...range, y: [parseFloat(e.target.value), range.y[1]] })}
                    style={styles.inputField}
                    placeholder="Min value"
                />
                <span style={styles.separator}>-</span>
                <input
                    type="number"
                    value={range.y[1]}
                    step={rangeStep}
                    onChange={(e) => handleSetRange({ ...range, y: [range.y[0], parseFloat(e.target.value)] })}
                    style={styles.inputField}
                    placeholder="Max value"
                />
                <br />
                <input
                    type="checkbox"
                    checked={applyRange.y}
                    onChange={(e) => {setApplyRange({ ...applyRange, y: e.target.checked })}}
                    style={styles.checkbox}
                />
            </div>

            <div style={styles.inputGroup}>
                <label style={styles.rangeLabel}>Z:</label>
                <input
                    type="number"
                    value={range.z[0]}
                    step={rangeStep}
                    onChange={(e) => handleSetRange({ ...range, z: [parseFloat(e.target.value), range.z[1]] })}
                    style={styles.inputField}
                    placeholder="Min value"
                />
                <span style={styles.separator}>-</span>
                <input
                    type="number"
                    value={range.z[1]}
                    step={rangeStep}
                    onChange={(e) => handleSetRange({ ...range, z: [range.z[0], parseFloat(e.target.value)] })}
                    style={styles.inputField}
                    placeholder="Max value"
                />
                <br />
                <input
                    type="checkbox"
                    checked={applyRange.z}
                    onChange={(e) => {setApplyRange({ ...applyRange, z: e.target.checked })}}
                    style={styles.checkbox}
                />
            </div>
        </div>
    );
};

export default RangeController;
