import api from 'api/axiosConfig';

class SessionModel {
    async createSession(data) {
        try {
            const response = await api.post('/session', data);
            return response.data;
        } catch (error) {
            console.error('Failed to create session:', error);
            throw error;
        }
    }
}

const SessionModelInstance = new SessionModel();
export default SessionModelInstance;