import React, { useState, useEffect } from 'react';
import { isMobile, isIPad13 } from 'react-device-detect';

const FeatureModal = ({ currentFeature, feature, expanded, handleToggleFeatureExpand }) => {
  const [pointerLockState, setPointerLockState] = useState(false);

  useEffect(() => {
    const handlePointerLockChange = () => {
      if (document.pointerLockElement) {
        setPointerLockState(true);
      } else {
        setPointerLockState(false);
      }
    };

    const handleExit = () => {
      if (document.pointerLockElement) {
        setPointerLockState(false);
      }
      document.removeEventListener('pointerlockchange', handlePointerLockChange);
    };

    document.addEventListener('pointerlockchange', handlePointerLockChange);
    return () => handleExit();
  }, []);

  // Base styles common to both states
  const baseModalStyle = {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.7)',
    padding: '10px',
    borderRadius: '8px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    zIndex: 1000,
    boxSizing: 'border-box',
  };

  // Styles when the modal is not expanded
  const collapsedModalStyle = {
    width: '60%',
    maxWidth: '200px',
    // No need for maxHeight or overflowY in collapsed state
  };

  // Styles when the modal is expanded
  const expandedModalStyle = {
    width: '90%',
    maxWidth: '500px',
    maxHeight: '80vh',
    overflowY: 'auto',
  };

  return (
    <div>
      {(pointerLockState || isMobile || isIPad13) && currentFeature && (
        <div
          style={{
            ...baseModalStyle,
            ...(expanded ? expandedModalStyle : collapsedModalStyle),
          }}
          onClick={() => {
            if (isMobile || isIPad13) {
              handleToggleFeatureExpand();
            }
          }}
        >
          {!expanded && (
            <h3
              style={{
                margin: '0 0 10px 0',
                textAlign: 'center',
                fontSize: '1.2em',
              }}
            >
              {(isMobile || isIPad13) ? 'Click to Inspect' : 'Press E to inspect'}
            </h3>
          )}
          {expanded && (
            <div style={{ textAlign: 'center' }}>
              <h3
                style={{
                  margin: '0 0 10px 0',
                  fontSize: '1.4em',
                }}
              >
                {feature.name}
              </h3>
              {feature.prop && (
                <img
                  src={feature.prop}
                  alt="Feature"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100vh',
                    objectFit: 'contain',
                    display: 'block',
                    margin: '10px auto',
                    borderRadius: '5px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                  }}
                />
              )}
              <p
                style={{
                  fontSize: '1em',
                  lineHeight: '1.4em',
                  margin: '0 10px',
                  textAlign: 'left',
                }}
              >
                {feature.description}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FeatureModal;
