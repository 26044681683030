import React from 'react';
import ArtHeader from 'asset/ArtHeader.svg';

const MissionStatement = () => {
    const styles = {
        container: {
            width: '100%',
            minHeight: '15vh',
            padding: '5%',
            backgroundImage: `url(${ArtHeader})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            boxSizing: 'border-box',
        },
        title: {
            width: '80%',
            maxWidth: '500px',
            color: 'white',
            fontSize: 'clamp(24px, 5vw, 48px)', // Responsive font size
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '600',
            lineHeight: '1.4',
            textAlign: 'center',
            wordWrap: 'break-word',
            marginTop: '40px',
        },
        description: {
            width: '90%',
            maxWidth: '1722px',
            opacity: 0.8,
            color: '#D8DADC',
            fontSize: 'clamp(16px, 4vw, 40px)', // Responsive font size
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '600',
            lineHeight: '1.5',
            textAlign: 'left',
            wordWrap: 'break-word',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.title}>Mission Statement</div>
            <div style={styles.description}>
                To merge art and technology through spatial computing, creating immersive archive exhibitions and
                interactive digital twins that preserve, innovate, and inspire.
            </div>
        </div>
    );
};

export default MissionStatement;
