import React from 'react';
import HeroSection from '../components/demo/HeroSection';
import Interactive3DModel from '../components/demo/Interactive3DModel';
import TrustedBySection from 'components/demo/TrustedBySection';
import HowItWorks from '../components/demo/HowItWorks';
import ImmersiveExperience from '../components/demo/ImmersiveExperience';
import Features from '../components/demo/Features';
import BuildCTA from '../components/demo/BuildCTA';
import Footer from '../components/demo/Footer';

const LandingPage = ({ user }) => {

  return (
    <div style={{
      width: '100%',
    }}>
        <HeroSection user={user} />
        <Interactive3DModel />
        <HowItWorks />
        {/* <ImmersiveExperience /> */}
        {/* <Features /> */}
        <TrustedBySection />
        <BuildCTA user={user} />
        <Footer />
    </div>
  );
};

export default LandingPage;
