// WaterMark.js
import React, { useState, useEffect } from 'react';
import icon from 'asset/kaleida_icon.png';

const WaterMark = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 2000,
      }}
      onClick={handleLogoClick}
    >
      <img
        src={icon}
        alt="Kaleida Logo"
        style={{
          height: isMobile ? '30px' : '50px',
          width: isMobile ? '30px' : '50px',
          marginRight: isMobile ? '0px' : '10px',
          marginLeft: isMobile ? '10px' : '0px',
        }}
      />
      <div
        style={{
          fontFamily: '"Poppins", sans-serif',
          fontSize: isMobile ? '16px' : '24px',
          fontWeight: 'bold',
          marginRight: isMobile ? '10px' : '0',
        }}
      >
        Kaleida
      </div>
    </div>
  );
};

export default WaterMark;
