// CameraList.js
import React from 'react';
import SidebarHeader from './SidebarHeader';

const CameraList = ({ config, currentCamera, setCurrentCamera, onClose }) => {
  if (document.pointerLockElement) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%', // Ensures full width
        backgroundColor: '#333',
        color: '#fff',
        zIndex: 2000,
        transition: 'transform 0.3s ease-in-out',
        transform: 'translateX(0)',
        boxSizing: 'border-box',
      }}
    >
      {/* Sidebar Header */}
      <SidebarHeader title="View Points" onBack={onClose} />

      {/* Content Section */}
      <div
        style={{
          padding: '15px',
          overflowY: 'auto',
          height: 'calc(100% - 72px)', // Adjust height to prevent overflow
        }}
      >
        {/* List of Camera View Points */}
        <ul
          style={{
            listStyleType: 'none',
            padding: '0',
            margin: '0',
          }}
        >
          {config.cameraList.map((camera, index) => (
            <li
              key={index}
              style={{
                padding: '8px 12px',
                fontSize: '16px',
                cursor: 'pointer',
                backgroundColor: currentCamera === index ? '#555' : '#333',
                marginBottom: '5px',
                borderRadius: '5px',
                textAlign: 'center',
                transition: 'background-color 0.3s',
                color: '#fff',
              }}
              onClick={() => setCurrentCamera(index)}
            >
              {camera.name ? camera.name : `Viewpoint ${index + 1}`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CameraList;
