import React, { useState, useEffect, useRef } from 'react';
import vrInteractionIcon from 'asset/avp.svg';
import webInteractionIcon from 'asset/window-cursor.svg';
import reconstructionIcon from 'asset/ar-scan-cube-2.svg';
import multisourceIcon from 'asset/inputIcon.svg';
import modelgen from 'asset/modelgen.gif';
import embeddedDemo from 'asset/embedded-demo.png';
import videoThumbnail from 'asset/video-thumbnail.png';
import tourGuide from 'asset/tour_guide.gif';
import gallery from 'asset/gallery.gif';
import vr from 'asset/vr.gif';
import AOS from 'aos';
import 'aos/dist/aos.css';

const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};

const styles = {
  gradientText: {
    background: 'linear-gradient(90deg, #81B6FB 0%, #A0BFFB 15%, #B9ADE3 30%, #FFFFFF 50%, #CCA3BC 70%, #A0BFFB 85%, #81B6FB 100%)',
    backgroundSize: '200% auto',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    animation: 'gradientAnimation 4s ease infinite',
  },
};

const appStyles = (isMobile) => ({
  container: {
    width: '100%',
    background: '#040507',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    paddingBottom: '50px', // Add some padding at the bottom
  },
  howItWorksSection: {
    width: '100%', // Full width
    maxWidth: '1200px',
    padding: isMobile ? '50px 10px' : '80px 20px', // Add horizontal padding on mobile
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    textAlign: 'center',
    fontSize: isMobile ? '24px' : '36px', // Slightly smaller font size on mobile
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '600',
    marginBottom: '40px', // Reduce margin on mobile
    padding: isMobile ? '0 10px' : '0', // Add horizontal padding
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)', // One column on mobile
    gap: '20px', // Smaller gap on mobile
    width: '100%',
    padding: isMobile ? '0 10px' : '0', // Add horizontal padding on mobile
  },
  card: {
    backgroundColor: '#1A1A1A',
    padding: '20px', // Reduced padding
    borderRadius: '10px',
    textAlign: 'center',
    transition: 'transform 0.3s ease',
    // cursor: 'pointer',
    maxWidth: '300px', // Limit the width of the cards
    margin: '0 auto', // Center the card horizontally
  },
  cardHover: {
    transform: 'translateY(-10px)',
  },
  icon: {
    height: isMobile ? '40px' : '80px', // Smaller icons on mobile
    marginBottom: '20px', // Reduced margin
  },
  cardTitle: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '18px', // Slightly smaller font size
    fontWeight: '600',
    marginBottom: '10px', // Reduced margin
  },
  cardDescription: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '400',
    fontSize: '14px', // Slightly smaller font size
    color: '#CCCCCC',
  },
  videoSection: {
    width: '90%', // Full width
    maxWidth: '1200px',
    margin: '40px 0', // Reduced margin
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    width: '80%', // Full width
    boxShadow: '0 10px 30px rgba(0,0,0,0.5)',
  },
  section: {
    width: '90%', // Full width
    // maxWidth: '1600px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: isMobile ? '50px 10px' : '140px 20px', // Add horizontal padding on mobile
    gap: '30px', // Reduced gap
    // border: '3px solid #1A1A1A',
  },
  textBlock: {
    width: '100%', // Full width
    maxWidth: isMobile ? '80%' : '100%', // Add a max width on larger screens
    fontSize: isMobile ? '32px' : '48px', // Use vw for better responsiveness
    fontFamily: 'Poppins',
    fontWeight: '700',
    lineHeight: isMobile ? '32px' : '48px',
    background: 'linear-gradient(180deg, #81B6FB 0%, #B9ADE3 70%, #FFFFFF 85%, #CCA3BC 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    width: '100%',
    maxWidth: isMobile ? '90%' : '1200px', // Limit max width
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: isMobile ? '0 10px' : '0', // Add horizontal padding on mobile
  },
  image: {
    width: '1200px',
    maxWidth: '90%',
    height: 'auto',
    transition: 'transform 0.3s ease',
    
  },
  imageHover: {
    transform: 'scale(1.05)',
  },
});


const WorkCard = ({ icon, title, description }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (hovered) => {
    setIsHovered(hovered);
  };

  return (
    <div
      style={{ ...appStyles().card, ...(isHovered ? appStyles().cardHover : {}) }}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <img src={icon} alt={title} style={appStyles().icon} />
      <div style={appStyles().cardTitle}>{title}</div>
      <div style={appStyles().cardDescription}>{description}</div>
    </div>
  );
};

const HowItWorksTop = ({ isMobile }) => {

  useEffect(() => {
    AOS.init({ duration: 1000, });
  }, []);

  return (
    <div style={appStyles(isMobile).howItWorksSection}>
      <h2 style={{ ...appStyles(isMobile).title, ...styles.gradientText }} data-aos="fade-down">How it works</h2>
      <div style={appStyles(isMobile).cardsContainer} data-aos="fade-down">
        <WorkCard
          icon={multisourceIcon}
          title="Easy Capture"
          description="Capture your space with any cellphones or cameras."
        />
        <WorkCard
          icon={reconstructionIcon}
          title="HQ 3D Reconstruction"
          description="Powered by Kaleida Engine V1 and V2 Beta."
        />
        <WorkCard
          icon={webInteractionIcon}
          title="Model Customization"
          description="Edit and customize your 3D immersive experience with Kaleida Editor."
        />
        <WorkCard
          icon={vrInteractionIcon}
          title="Immersive Interaction"
          description="Experience and share immersive experience on all platforms."
        />
      </div>
    </div>
  );
};

const SectionWithImage = ({ isMobile, text, imageSrc, reverse }) => {
  const [isHovered, setIsHovered] = useState(false);
  const appliedStyles = appStyles(isMobile);

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1500,
  //     once: false,     // Ensures animation happens only once
  //     offset: 100,    // Adjust to control when animation starts in viewport
  //   });
  // }, []);

  return (
    <div
      style={{
        ...appliedStyles.section,
        // flexDirection: isMobile
        //   ? 'column'
        //   : reverse
        //   ? 'row-reverse'
        //   : 'row',
        flexDirection: 'column'
      }}
    // data-aos={reverse ? "fade-left" : "fade-right"}
    // data-aos-offset="300"
    // data-aos-easing="ease-in-sine"
    >
      <div style={{ ...appliedStyles.textBlock, ...styles.gradientText }}>{text}</div>
      <div
        style={appliedStyles.imageContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={imageSrc}
          alt="Illustration"
          style={{
            ...appliedStyles.image,
            ...(isHovered ? appliedStyles.imageHover : {}),
          }}
        />
      </div>
    </div>
  );
};

const HowItWorksSection = () => {
  const [width] = useWindowSize();
  const isMobile = width < 768;

  const sectionsRef = useRef([]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // Use the viewport as the root
      threshold: 0.3, // Adjust to trigger when half of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Clear the timer if the element leaves the viewport
        if (!entry.isIntersecting && timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        // Set a timer to trigger scroll after 0.5 seconds if the element is in view
        if (entry.isIntersecting && !timeoutRef.current) {
          timeoutRef.current = setTimeout(() => {
            entry.target.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            timeoutRef.current = null; // Clear timer after scrolling
          }, 100); // Adjust delay as needed
        }
      });
    }, observerOptions);

    // Observe each section
    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      // Clean up observer and timeout on component unmount
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);


  // Helper to add refs dynamically
  const addToRefs = (el) => {
    if (!isMobile) {
      if (el && !sectionsRef.current.includes(el)) {
        sectionsRef.current.push(el);
      }
    }
  };

  return (
    <div style={appStyles(isMobile).container}>
      <style>{`
        @keyframes gradientAnimation {
          0% {
            background-position: 0% center;
          }
          100% {
            background-position: -200% center;
          }
        }
      `}</style>
      <HowItWorksTop isMobile={isMobile} />

      <div style={appStyles(isMobile).videoSection}>
        <video
          style={appStyles(isMobile).video}
          controls
          poster={videoThumbnail}
        >
          <source
            src="https://s3.amazonaws.com/kaleida.space/assets/kaleida-demo-v2.mov"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <section ref={addToRefs}>
        <SectionWithImage
          isMobile={isMobile}
          text="Custom Model Generation & Editing Pipeline"
          imageSrc={modelgen}
        />
      </section>
      <section ref={addToRefs}>
        <SectionWithImage
          isMobile={isMobile}
          text="Gamified First Person Immersive Experience"
          imageSrc={gallery}
          reverse
        />
      </section>
      <section ref={addToRefs}>
        <SectionWithImage
          isMobile={isMobile}
          text="AI Powered Tour Guide"
          imageSrc={tourGuide}
        />
      </section>
      <section ref={addToRefs}>
        <SectionWithImage
          isMobile={isMobile}
          text="1:1 Real World on VR headsets"
          imageSrc={vr}
          reverse
        />
      </section>
    </div>
  );
};

export default HowItWorksSection;
