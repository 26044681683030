// WarningModal.js
import React from 'react';

const styles = {
  warningModal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#222222',
    paddingleft: '60px',
    paddingRight: '60px',
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    width: '50%', // Adjust the width as needed
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the modal contents
    justifyContent: 'flex-start', // Align items to the top
    borderRadius: '10px',
  },
  warningOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  warningTitleContainer: {
    paddingTop: '60px',
    paddingBottom: '60px',
    maxWidth: '70%',
    textAlign: 'left',
  },
  warningTitle: {
    fontSize: '26px',
    fontFamily: '"Poppins", sans-serif',
    color: '#fff',
    marginTop: '20px',
  },
  warningCloseButton: {
    position: 'absolute',
    right: '10px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#fff',
    fontSize: '24px',
    fontFamily: '"Poppins", sans-serif',
  },
  warningMessage: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '20px',
    color: '#fff',
    textAlign: 'left',
    maxWidth: '100%',
    marginTop: '20px',
    wordWrap: 'break-word',
  },
  warningNote: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '20px',
    color: '#fff',
    textAlign: 'left',
    marginTop: '20px',
    maxWidth: '100%',
    wordWrap: 'break-word',
  },
};



const WarningModal = ({ onClose, message }) => {
  return (
    <>
      <div style={styles.warningOverlay}></div>
      <div style={styles.warningModal}>
          <button style={styles.warningCloseButton} onClick={onClose}>
            &times;
          </button>
          <div style={styles.warningTitleContainer}>
            <h2 style={styles.warningTitle}>Job Limit Reached</h2>
            <p style={styles.warningMessage}>{message}</p>
            {/* <p style={styles.warningNote}>
              We're testing features now and will raise the quota as we expand.
            </p> */}
        </div>

        
        </div>
    </>
  );
};

export default WarningModal;
