// UserGuide.js
import React from 'react';
import SidebarHeader from './SidebarHeader';

// import image from src/assets
import ESC_KEY from 'asset/Keyboard_Mouse/White/T_Esc_Key_White.png';
import MOUSE_MOVE_KEY from 'asset/Keyboard_Mouse/White/T_Mouse_XY_Key_White.png';
import W_KEY from 'asset/Keyboard_Mouse/White/T_W_Key_White.png';
import A_KEY from 'asset/Keyboard_Mouse/White/T_A_Key_White.png';
import S_KEY from 'asset/Keyboard_Mouse/White/T_S_Key_White.png';
import D_KEY from 'asset/Keyboard_Mouse/White/T_D_Key_White.png';
import UP_KEY from 'asset/Keyboard_Mouse/White/T_Up_Key_White.png';
import DOWN_KEY from 'asset/Keyboard_Mouse/White/T_Down_Key_White.png';
import LEFT_KEY from 'asset/Keyboard_Mouse/White/T_Left_Key_White.png';
import RIGHT_KEY from 'asset/Keyboard_Mouse/White/T_Right_Key_White.png';
import SHIFT_KEY from 'asset/Keyboard_Mouse/White/T_Shift_Key_White.png';
import SHIFT_WIDE_KEY from 'asset/Keyboard_Mouse/White/T_Shift_Super_Wide_Key_White.png';
import SPACE_KEY from 'asset/Keyboard_Mouse/White/T_Space_Key_White.png';
import C_KEY from 'asset/Keyboard_Mouse/White/T_C_Key_White.png';
import R_KEY from 'asset/Keyboard_Mouse/White/T_R_Key_White.png';
import F_KEY from 'asset/Keyboard_Mouse/White/T_F_Key_White.png';
import E_KEY from 'asset/Keyboard_Mouse/White/T_E_Key_White.png';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '5px',
  },
  text: (m) => ({
    color: 'white',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '16px',
    marginTop: `${m}px`,
  }),
  walkKeyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  rowWithGap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '20px',
  }
};

const ImageWithText = ({ src, text, height=65 }) => {
  return (
    <div style={styles.container}>
      <img
        src={src}
        alt={text}
        style={{ height: `${height}px` }}
      />
      {text && <p style={styles.text(80 - height)}>{text}</p>}
    </div>
  );
};

const UserGuide = ({ onClose }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%',
        backgroundColor: '#333',
        zIndex: 2000,
        transition: 'transform 0.3s ease-in-out',
        boxSizing: 'border-box',
      }}
    >
      {/* Sidebar Header */}
      <SidebarHeader title="User Guide" onBack={onClose} />

      {/* Content Section */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '30px',
          padding: '20px',
        }}
      >
        <div style={styles.rowWithGap}>
          {/* <ImageWithText src={ESC_KEY} text="stop" /> */}
          <ImageWithText src={MOUSE_MOVE_KEY} text="Camera" />
          <div style={{width: '30px'}}></div>
          <ImageWithText src={R_KEY} text="Move Up" />
          <ImageWithText src={F_KEY} text="Move Down" />
        </div>

        <div style={styles.rowWithGap}>
          <div style={styles.walkKeyContainer}>
            <div style={styles.row}>
              <ImageWithText src={W_KEY} text="" height={50} />
            </div>
            <div style={styles.row}>
              <ImageWithText src={A_KEY} text="" height={50} />
              <ImageWithText src={S_KEY} text="" height={50} />
              <ImageWithText src={D_KEY} text="" height={50} />
            </div>
            <p>Move</p>
          </div>

          <p>or</p>

          <div style={styles.walkKeyContainer}>
            <div style={styles.row}>
              <ImageWithText src={UP_KEY} text="" height={50} />
            </div>
            <div style={styles.row}>
              <ImageWithText src={LEFT_KEY} text="" height={50} />
              <ImageWithText src={DOWN_KEY} text="" height={50} />
              <ImageWithText src={RIGHT_KEY} text="" height={50} />
            </div>
            <p>Move</p>
          </div>
        </div>

        <div style={styles.rowWithGap}>
          <ImageWithText src={SHIFT_WIDE_KEY} text="hold to run" height={75} />
          {/* <div style={{width: '20px'}}></div> */}
          <ImageWithText src={SPACE_KEY} text="Jump" height={75} />
          <ImageWithText src={C_KEY} text="Squat" />
        </div>

        <div style={styles.rowWithGap}>
          <ImageWithText src={E_KEY} text="Inspect" />
        </div>

      </div>
    </div>
  );
};

export default UserGuide;
