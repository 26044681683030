import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import editProfileIcon from '../asset/editProfileIcon.jpg';
import userControllerInstance from 'controllers/UserController';

import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const styles = {
    container: {
        paddingTop: '200px',
        width: '100vw',
        height: '100vh',
        padding: '100px',
        background: '#040507',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '40px',
        boxSizing: 'border-box',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '40px',
        width: '50%',
    },
    header: {
        color: '#F9F9F9',
        fontSize: '29px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '700',
        lineHeight: '38.40px',
        letterSpacing: '0.11px',
        wordWrap: 'break-word',
    },
    sectionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '32px',
        width: '100%',
        margin: '0 auto',
    },
    labelText: {
        color: '#F9F9F9',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        wordWrap: 'break-word',
        width: '80px',
      },
      nameInput: {
        flexGrow: 1,
        padding: '8px',
        backgroundColor: '#1E1F22',
        border: '1px solid #31343E',
        borderRadius: '4px',
        color: '#F9F9F9',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
      },
      buttonContainer: {
        display: 'flex',
        gap: '8px',
        marginTop: '8px',
      },
      saveButton: {
        padding: '8px 16px',
        backgroundColor: '#3366FF',
        borderRadius: '4px',
        border: 'none',
        color: '#FFFFFF',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        cursor: 'pointer',
      },
      cancelButton: {
        padding: '8px 16px',
        backgroundColor: '#4B4E5A',
        borderRadius: '4px',
        border: 'none',
        color: '#FFFFFF',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        cursor: 'pointer',
      },
      iconClickable: {
        cursor: 'pointer',
      },
    card: {
        alignSelf: 'stretch',
        // padding: '1px',
        borderRadius: '4px',
        border: '2px #31343E solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
    },
    cardHeader: {
        padding: '4px 16px',
        background: '#31343E',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
    },
    cardHeaderTextContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingRight: '369px', // Adjusted per original inline style
    },
    cardHeaderText: {
        color: '#A8A9AD',
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        wordWrap: 'break-word',
    },
    cardRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '16px',
        width: '100%',
        boxSizing: 'border-box',
      },
      cardRowContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
      },
    cardRowBorderTop: {
        borderTop: '1px #31343E solid',
    },
    text: {
        color: '#F9F9F9',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.11px',
        wordWrap: 'break-word',
    },
    iconPlaceholder: {
        width: '24px',
        height: '24px',
        background: '#F9F9F9',
    },
    passwordText: {
        width: '265px',
        color: '#F9F9F9',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.11px',
        wordWrap: 'break-word',
    },
    subscriptionCard: {
        alignSelf: 'stretch',
        // padding: '1px',
        borderRadius: '4px',
        border: '2px #31343E solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
    },
    subscriptionHeader: {
        padding: '4px 16px',
        background: '#31343E',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
    },
    subscriptionHeaderTextContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingRight: '390px', // Adjusted per original inline style
    },
    subscriptionHeaderText: {
        color: '#A8A9AD',
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        wordWrap: 'break-word',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '20px',
        gap: '10px',
        width: '100%',
        boxSizing: 'border-box',
    },
    rowHover: {
        backgroundColor: 'gray',
        cursor: 'pointer'
    },
    subscriptionPriceContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    subscriptionPrice: {
        fontSize: '16px',
        fontWeight: '300',
        color: 'rgba(255, 255, 255, 0.5)',
    },
    paymentMethodContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '10px',
        width: '100%',
        boxSizing: 'border-box',
    },
    paymentMethodText: {
        color: '#F9F9F9',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.11px',
        wordWrap: 'break-word',
        marginLeft: '15px',
        paddingRight: '339px', // Adjusted per original inline style
    },
    paymentMethodImage: {
        width: '476px',
        height: '100px',
        paddingRight: '339px', // Adjusted per original inline style
    },
    settingsCard: {
        alignSelf: 'stretch',
        height: '117px',
        // padding: '1px',
        borderRadius: '4px',
        border: '2px #31343E solid',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
    },
    settingsHeader: {
        padding: '4px 16px',
        background: '#31343E',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
    },
    settingsHeaderTextContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingRight: '403px', // Adjusted per original inline style
    },
    settingsHeaderText: {
        color: '#A8A9AD',
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        wordWrap: 'break-word',
    },
    publicAccountContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '8px',
        padding: '17px 12px 16px 12px',
        borderTop: '1px #31343E solid',
        width: '100%',
        boxSizing: 'border-box',
    },
    publicAccountToggleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '282px', // Adjusted per original inline style
        width: '100%',
        boxSizing: 'border-box',
    },
    publicAccountText: {
        color: '#F9F9F9',
        fontSize: '16px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '24px',
        wordWrap: 'break-word',
    },
    toggleSwitch: {
        width: '43px',
        height: '22px',
        position: 'relative',
        borderRadius: '11px',
        cursor: 'pointer',
        transition: 'background 0.3s ease',
    },
    toggleKnob: {
        width: '22px',
        height: '22px',
        position: 'absolute',
        top: '0px',
        background: '#CACACA',
        boxShadow: '0px 3px 1px rgba(0, 0, 0, 0.05)',
        borderRadius: '50%',
        transition: 'left 0.3s ease',
    },
    toggleDescription: {
        color: '#CACACA',
        fontSize: '12px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: '400',
        lineHeight: '18px',
        wordWrap: 'break-word',
    },
    updateProfileButton: {
        width: '364px',
        height: '54px',
        position: 'relative',
        background: '#3366FF',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    updateProfileButtonText: {
        color: '#FDFDFD',
        fontSize: '18px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '400',
        lineHeight: '32px',
        textAlign: 'center',
        zIndex: '1',
    },
    splitLine: {
        width: '100%',
        height: '2px',
        background: 'rgba(255, 255, 255, 0.3)',
    },
};

const UserProfile = ({ user }) => {

    const [loading, setLoading] = useState(true);

    const [userInfo, setUserInfo] = useState({});
    const [name, setName] = useState('');
    const [isEditingName, setIsEditingName] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const navigate = useNavigate();

    const [subscriptionRowIsHovered, setSubscriptionRowIsHovered] = useState(false);
    const [subscriptionManagementRowIsHovered, setSubscriptionManagementRowIsHovered] = useState(false);

    useEffect(() => {
        const getUserInfo = async () => {
          try {
            let userDB = await userControllerInstance.getUserById(user.uid);
            setUserInfo(userDB);
            setName(userDB.name || '');
          } catch (error) {
            console.error('Failed to get user info:', error);
          } finally {
            setLoading(false);
          }
        };
        getUserInfo();
      }, [user.uid]);

    const handleEditNameClick = () => {
        setIsEditingName(true);
    };


    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleSaveName = async () => {
        try {
            const dataToSend = {
                username: name || undefined,
            };
            const updatedUser = await userControllerInstance.updateBulkUsernameById(user.uid, dataToSend);
            setUserInfo(updatedUser);
            alert('Name updated successfully!');
            setIsEditingName(false);
            window.location.reload();

        } catch (error) {
            console.error('Failed to update name:', error);
            alert('Failed to update name');
        }
    };

    const handleCancelEditName = () => {
        setName(userInfo.name || '');
        setIsEditingName(false);
      };

    const handleToggle = () => {
        setIsPublic(prevState => !prevState);
    };
    const handleArrowClick = () => {
        navigate('/subscribe');
    };

    const handleManageBilling = async (subscriptionId) => {
        try {
            const session = await userControllerInstance.createBillingPortalSession(subscriptionId);

            if (session.url) {
                window.location.href = session.url; // Redirect to the Stripe checkout page
            } else {
                console.error('Error: Unable to create session');
            }
        } catch (error) {
            console.error('Checkout failed:', error);
        }
    };

    return (
        <div style={styles.page}>
            <Header user={user} />
            <div style={styles.container}>
                <div style={styles.contentWrapper}>
                    {/* Header */}
                    <div style={styles.header}>Account Setting</div>

                    {/* Sections Container */}
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div style={styles.sectionsContainer}>
                            {/* Account Details Card */}
                            {true && <div style={styles.card}>
                                {/* Card Header */}
                                <div style={styles.cardHeader}>
                                    <div style={styles.cardHeaderTextContainer}>
                                        <div style={styles.cardHeaderText}>Account Details</div>
                                    </div>
                                </div>

                                {/* Name Row */}
                                <div style={{ ...styles.cardRow, ...styles.cardRowBorderTop }}>
                                {!isEditingName ? (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                                    <div style={styles.text}>{userInfo.name || 'Your Name'}</div>
                                    <div
                                        style={{ ...styles.iconPlaceholder, ...styles.iconClickable }}
                                        onClick={handleEditNameClick}
                                    >
                                        <img
                                        src={editProfileIcon}
                                        alt="Edit Profile Icon"
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </div>
                                    </div>
                                ) : (
                                    <div style={{ width: '100%' }}>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={handleNameChange}
                                        style={styles.nameInput}
                                    />
                                    <div style={styles.buttonContainer}>
                                        <button onClick={handleSaveName} style={styles.saveButton}>
                                        Save
                                        </button>
                                        <button onClick={handleCancelEditName} style={styles.cancelButton}>
                                        Cancel
                                        </button>
                                    </div>
                                    </div>
                                )}
                                </div>
                            </div>}

                            {/* Subscription Card */}
                            <div style={styles.subscriptionCard}>
                                {/* Subscription Header */}
                                <div style={styles.subscriptionHeader}>
                                    <div style={styles.subscriptionHeaderTextContainer}>
                                        <div style={styles.subscriptionHeaderText}>Subscription</div>
                                    </div>
                                </div>

                                {/* Starter (Monthly) Row */}
                                <div
                                    style={{
                                        ...styles.row,
                                        ...(subscriptionRowIsHovered ? styles.rowHover : {}),
                                    }}
                                    onMouseEnter={() => setSubscriptionRowIsHovered(true)}
                                    onMouseLeave={() => setSubscriptionRowIsHovered(false)}
                                    onClick={handleArrowClick}
                                >
                                    <div style={styles.subscriptionPriceContainer}>
                                        {userInfo.subscription ? (
                                            <div style={styles.text}>
                                                {userInfo.subscription.plan} 
                                                {userInfo.subscription.status !== 'active' && ' (not active)'}
                                            </div>
                                        ) : (
                                            <div style={styles.text}>Subscribe to a plan</div>
                                        )}
                                    </div>
                                    <ArrowForwardIosIcon />
                                </div>

                                {userInfo.subscription && (<>
                                    <div style={styles.splitLine}></div>

                                    {/* Manage Payment Method */}
                                    <div
                                        style={{
                                            ...styles.row,
                                            ...(subscriptionManagementRowIsHovered ? styles.rowHover : {}),
                                        }}
                                        onMouseEnter={() => setSubscriptionManagementRowIsHovered(true)}
                                        onMouseLeave={() => setSubscriptionManagementRowIsHovered(false)}
                                        onClick={() => {
                                            if (userInfo.subscription.status === 'active') {
                                                handleManageBilling(userInfo.subscription.subscriptionId);
                                            } else {
                                                handleArrowClick();
                                            }
                                        }}
                                    >
                                        <div style={styles.subscriptionPriceContainer}>
                                            <div style={styles.text}>
                                                {userInfo.subscription.status === 'active'
                                                    ? 'Manage Payment Method'
                                                    : 'Renew your subscription'}
                                            </div>
                                        </div>
                                        <ArrowForwardIosIcon />
                                    </div>
                                </>)}
                            </div>

                            {/* Settings Card */}
                            {false && <div style={styles.settingsCard}>
                                {/* Settings Header */}
                                <div style={styles.settingsHeader}>
                                    <div style={styles.settingsHeaderTextContainer}>
                                        <div style={styles.settingsHeaderText}>Setting</div>
                                    </div>
                                </div>

                                {/* Public Account Toggle */}
                                <div style={styles.publicAccountContainer}>
                                    <div style={styles.publicAccountToggleContainer}>
                                        <div style={styles.publicAccountText}>Public Account</div>
                                        <div
                                            style={{
                                                ...styles.toggleSwitch,
                                                background: isPublic ? '#3366FF' : '#4B4E5A'
                                            }}
                                            onClick={handleToggle}
                                        >
                                            <div
                                                style={{
                                                    ...styles.toggleKnob,
                                                    left: isPublic ? '21px' : '0px',
                                                    backgroundColor: isPublic ? '#3366FF' : '#CACACA'
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div style={styles.toggleDescription}>
                                        All users can see your models.
                                    </div>
                                </div>
                            </div>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserProfile;