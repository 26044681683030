// Mask.js
import React, { useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';

import { isMobile, isIPad13 } from 'react-device-detect';

const Mask = ({ isAnySidebarOpen, handleSidebarToggle }) => {
  const [show, setShow] = useState(false);
  const [pointerLockState, setPointerLockState] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    const handlePointerLockChange = () => {
      if (document.pointerLockElement) {
        setPointerLockState(true);
      } else {
        setPointerLockState(false);
      }
    };

    const handleExit = () => {
      if (document.pointerLockElement) {
        setPointerLockState(false);
        document.exitPointerLock();
      }
      document.removeEventListener('pointerlockchange', handlePointerLockChange);
    };

    document.addEventListener('pointerlockchange', handlePointerLockChange);
    return () => handleExit();
  }, []);

  useEffect(() => {
    const handleSetShowTimer = () => {
      setShow(false);
      timer.current = setTimeout(() => {
        setShow(true);
      }, 1500);
    };

    handleSetShowTimer();
    return () => clearTimeout(timer.current);
  }, [pointerLockState]);

  useEffect(() => {
    if (!pointerLockState && !isAnySidebarOpen) {
      handleSidebarToggle('userGuide');
    }
  }, [show]);

  const maskLayout = () => (
    <div
      style={{
        position: 'absolute',
        background: 'rgba(0, 0, 0, 0.3)',
        height: '100vh',
        width: '100vw',
        zIndex: 2000,
      }}
    >
      {show ? (
        <button
          onClick={() => {
            document.body.requestPointerLock();
            handleSidebarToggle('');
          }}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            padding: '20px 40px',
            borderRadius: '10px',
            fontSize: '24px',
            cursor: 'pointer',
            fontFamily: '"Poppins", sans-serif',
            background: 'rgba(255, 255, 255, 0.8)',
            transform: isAnySidebarOpen ? 'translate(-100%)' : 'translate(-50%)',
          }}
        >
          Start Immersive Experience
        </button>
      ) : (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <ClipLoader size={50} color={'#123abc'} loading={!show} />
        </div>
      )}

    </div>
  );

  const noMaskLayout = () => (
    <div
      style={{
        position: 'absolute',
        top: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 2000,
      }}
    >
      {!isMobile && !isIPad13 && (
        <div
          style={{
            padding: '0 80px',
            borderRadius: '5px',
            background: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            fontFamily: '"Poppins", sans-serif',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            // marginTop: '20px',
          }}
        >
          <p>Press</p>
          <p style={{
            border: '2px solid white',
            padding: '0 7px',
            margin: '0 7px',
          }}>
            ESC
          </p>
          <p>to stop.</p>
        </div>
      )}
    </div>
  );

  return pointerLockState ? noMaskLayout() : maskLayout();
};

export default Mask;
