import JobModel from '../models/JobModel';

class JobController {
    constructor(jobModel) {
        this.jobModel = jobModel;
      }

    async createJob(jobData) {
        try {
            const job = await this.jobModel.createJob(jobData);
            return job;
        } catch (error) {
            console.error('Error creating job:', error);
        }
    }

    async getJobById(jobId) {
        try {
            const job = await this.jobModel.getJobById(jobId);
            return job;
        } catch (error) {
            console.error('Error getting job:', error);
        }
    }

    async updateJob(jobId, jobData) {
        try {
            const job = await this.jobModel.updateJob(jobId, jobData);
            return job;
        } catch (error) {
            console.error('Error updating job:', error);
        }
    }

    async deleteJob(jobId) {
        try {
            await this.jobModel.deleteJob(jobId);
            return jobId;
        } catch (error) {
            console.error('Error deleting job:', error);
        }
    }

    deleteS3Folder(url) {
        try {
            this.jobModel.deleteS3Folder(url);
        } catch (error) {
            console.error('Error deleting S3 folder:', error);
        }
    }

    async getAllPublicJobs(skip = 0, limit = 10) {
        try {
            const jobs = await this.jobModel.getAllPublicJobs(skip, limit);
            return jobs;
        } catch (error) {
            console.error('Error getting all public jobs:', error);
        }
    }
}

const JobControllerInstance = new JobController(JobModel);
export default JobControllerInstance;
