// SidebarHeader.js
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SidebarHeader = ({ title, onBack, icon }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      padding: '15px',
      backgroundColor: '#444',
    }}
  >
    <ArrowBackIcon
      style={{
        cursor: 'pointer',
        marginRight: '10px',
        fontSize: '24px',
        color: '#fff',
      }}
      onClick={onBack}
    />
    {icon && <div style={{ marginRight: '8px', color: '#fff' }}>{icon}</div>}
    <h3
      style={{
        fontFamily: '"Poppins", sans-serif',
        fontSize: '24px',
        fontWeight: '600',
        margin: 0,
        color: '#fff',
      }}
    >
      {title}
    </h3>
  </div>
);

export default SidebarHeader;
