import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { PointCloudRendererSlow } from '../calibration/ShapeRenderer';
import { readFileToBuffer, splatToPoints } from '../../Utils/DataLoader';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: undefined });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const Description = () => {
  const { width } = useWindowSize();
  const [pointCloudData, setPointCloudData] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = width < 768;

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const buffer = await readFileToBuffer(
          'https://s3.amazonaws.com/kaleida.space/models/splat/aba8c8ef-69ab-4ac7-82ba-81f662705987.splat'
        );
        const originPoints = splatToPoints(buffer);
        setPointCloudData(originPoints);
      } catch (err) {
        console.error('Failed to load point cloud or camera data', err);
      }
    };
    fetchData();
  }, []);

  const styles = {
    section: {
      position: 'relative',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row', // Overlay for mobile, side-by-side for desktop
      height: '100vh', // Full viewport height
      width: '100%',
      backgroundColor: '#000',
      color: '#fff',
      overflow: 'hidden',
    },
    contentDiv: {
      flex: isMobile ? 'unset' : 1, // Normal layout for desktop, overlay for mobile
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: isMobile ? 'center' : 'flex-start', // Center text for mobile
      padding: isMobile ? '0' : '40px', // Remove padding for mobile
      zIndex: isMobile ? 2 : 1, // Ensure text is above Canvas on mobile
      textAlign: isMobile ? 'center' : 'left', // Center-align text for mobile
      position: isMobile ? 'absolute' : 'relative', // Overlay for mobile
      top: isMobile ? '50%' : 'auto', // Center vertically for mobile
      left: isMobile ? '50%' : 'auto', // Center horizontally for mobile
      transform: isMobile ? 'translate(-50%, -50%)' : 'none', // Center position
      backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.6)' : 'transparent', // Add background for contrast on mobile
      width: isMobile ? '100%' : 'auto', // Full width for mobile
      height: isMobile ? '100%' : 'auto', // Full height for mobile
      boxSizing: 'border-box',
    },
    imgDiv: {
      flex: isMobile ? 'unset' : 1, // Normal layout for desktop, overlay for mobile
      position: isMobile ? 'absolute' : 'relative', // Overlay for mobile
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1, // Below contentDiv on mobile
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    canvas: {
      width: '100%',
      height: '100%',
    },
    h1: {
      fontSize: 'clamp(24px, 5vw, 48px)',
      fontWeight: '600',
      marginBottom: '20px',
      padding: '0 20px',
      fontFamily: '"Poppins", sans-serif',
    },
    p: {
      fontSize: 'clamp(14px, 4vw, 40px)',
      marginBottom: '20px',
      padding: '0 10px',
      color: '#DBDADC',
      textAlign: 'left',
      fontFamily: '"Poppins", sans-serif',
      lineHeight: 1.5,
      opacity: 0.8,
    },
  };
  
  return (
    <section style={styles.section}>
      <div style={styles.contentDiv}>
        <h1 style={styles.h1}>This is Kaleida for Arts</h1>
        <ol style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          <li style={styles.p}>Empower artists and institutions to achieve impactful exhibitions.</li>
          <li style={styles.p}>Enhance research with accurate, immersive 3D documentation.</li>
          <li style={styles.p}>Extend exhibition reach and duration to engage broader audiences.</li>
          <li style={styles.p}>Ensure accessibility for physically challenged individuals through virtual experiences.</li>
        </ol>
      </div>
      <div style={styles.imgDiv}>
        <Canvas
          camera={{ fov: 50, position: [1, 3, 2] }}
          style={{ width: '100%', height: '100%' }}
          gl={{ localClippingEnabled: true }}
        >
          {pointCloudData && (
            <PointCloudRendererSlow
              points={pointCloudData}
              calibration={{
                scale: 1,
                rotation: [0, 0, 0],
                translation: [0, 0, 0],
              }}
              range={{ x: [-20, 20], y: [-20, 20], z: [-20, 20] }}
              setLoading={setLoading}
            />
          )}
          <OrbitControls
            autoRotate
            autoRotateSpeed={1.0}
            enableDamping
            minPolarAngle={Math.PI / 2}
            maxPolarAngle={Math.PI / 2}
            enableZoom={false}
            enablePan={false}
            enableRotate={false}
          />
        </Canvas>
      </div>
    </section>
  );
};

export default Description;
