import React, { useEffect, useState } from 'react';

// Adjust pixel ratio based on CPU, GPU, and memory
const adjustPixelRatioBasedOnDevice = (deviceInfo, gpuInfo) => {
    const { cpuCores, memory } = deviceInfo;

    // Define performance levels based on CPU cores, memory size, and GPU vendor/renderer
    let performanceLevel = 'mid';

    // Thresholds for low-end, mid-range, and high-end devices
    if (cpuCores <= 4 || memory <= 4 || /Intel/.test(gpuInfo.vendor) || /Mali|Adreno/.test(gpuInfo.renderer)) {
        performanceLevel = 'low';
    } else if (cpuCores >= 8 && memory >= 8 && /NVIDIA|AMD/.test(gpuInfo.vendor)) {
        performanceLevel = 'high';
    }

    // Adjust pixel ratio based on performance level
    let pixelRatio;
    switch (performanceLevel) {
        case 'low':
            pixelRatio = 0.5; // Low-end devices
            break;
        case 'mid':
            pixelRatio = 1.0; // Mid-range devices
            break;
        case 'high':
            pixelRatio = 2.0; // High-end devices
            break;
        default:
            pixelRatio = 1.0; // Fallback if no match
    }

    return pixelRatio;
};

// Custom hook to gather device information (CPU, GPU, Memory)
const useDeviceInfo = () => {
    const [deviceInfo, setDeviceInfo] = useState({});
    const [gpuInfo, setGPUInfo] = useState({});
    const [advicePixelRatio, setAdvicePixelRatio] = useState(1.0);

    useEffect(() => {
        // Get CPU cores and memory
        const cpuCores = navigator.hardwareConcurrency || 'Unknown';
        const memory = navigator.deviceMemory || 'Unknown'; // Memory in GB

        // Get GPU info using WebGL
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (gl) {
            const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            setGPUInfo({ vendor, renderer });
        } else {
            setGPUInfo({ vendor: 'Unknown', renderer: 'Unknown' });
        }

        setDeviceInfo({
            cpuCores,
            memory,
        });
    }, []);

    useEffect(() => {
        if (deviceInfo.cpuCores && gpuInfo.vendor) {
            const newPixelRatio = adjustPixelRatioBasedOnDevice(deviceInfo, gpuInfo);
            setAdvicePixelRatio(newPixelRatio);
        }
    }, [deviceInfo, gpuInfo]);

    return { deviceInfo, gpuInfo, advicePixelRatio };
};

// Main component to display device info and adjusted pixel ratio
const DeviceInfo = () => {
    const { deviceInfo, gpuInfo, advicePixelRatio } = useDeviceInfo();

    return (
        <div>
            <h1>Device Info</h1>
            <p><strong>CPU Cores:</strong> {deviceInfo.cpuCores}</p>
            <p><strong>Memory:</strong> {deviceInfo.memory} GB</p>

            <h1>GPU Info</h1>
            <p><strong>Vendor:</strong> {gpuInfo.vendor}</p>
            <p><strong>Renderer:</strong> {gpuInfo.renderer}</p>

            <h1>Advice Pixel Ratio</h1>
            <p><strong>Pixel Ratio:</strong> {advicePixelRatio}</p>
        </div>
    );
};

export {
    useDeviceInfo,
    DeviceInfo,
};