import WebFont from 'webfontloader';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Auth from './components/authentication/AuthComponent';
import SignupForm from './components/authentication/UserProfileModal';
import { onAuthStateChanged } from 'firebase/auth';
import UserDashboard from './views/UserDashboard';
import ModelLibrary from './views/ModelLibrary';
import { auth } from './components/authentication/firebase';
import LandingPage from './views/Landing';
import ArtLandingPage from 'views/ArtLanding';
import NotFound from './views/NotFound';
import FAQ from './views/CaptureGuide';
import PrivacyPolicy from 'views/PrivacyPolicy';
import TermsOfService from 'views/TermOfService';
import './App.css';
import ModelViewer from './views/ModelViewer';
import Calibration from './views/Calibration';
import SubscribePlan from './views/SubsribePlan'
import Editor from './views/Editor';
import MultiSplatEditor from 'views/MultiSplatEditor';
import LoginPage from './components/authentication/LoginPage';
import RequireAuth from './RequireAuth';
import ErrorBoundary from './ErrorBoundary';
import { DeviceInfo } from 'components/immersive/UseDeviceInfo';
import UserProfile from 'views/UserProfile';

function Layout({ user, isLoading, setShowLogin }) {

    const location = useLocation();

    useEffect(() => {
        // Check if there's a saved scroll position
        const savedScrollPosition = sessionStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
            window.scrollTo(0, parseInt(savedScrollPosition, 10));
        }

        // Save scroll position when unmounting the component or navigating away
        return () => {
            sessionStorage.setItem('scrollPosition', window.scrollY);
        };
    }, [location]);

    useEffect(() => {
        const isModelPage = () => {
            const result = /^\/models/.test(location.pathname);
            // console.log('isModelPage', result);
            return result;
        };

        if (isModelPage()) {
            document.documentElement.style.overscrollBehavior = 'none';
        } else {
            document.documentElement.style.overscrollBehavior = 'auto';
        }
    }, [location]);

    return (
        <div className='App'>
            <main>
                <Routes>
                    <Route path="/" element={<>
                        <LandingPage user={user} />
                    </>} />
                    <Route path="/art" element={<ArtLandingPage user={user}/>} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/signup-form" element={<SignupForm />} />
                    <Route path="/dashboard" element={
                        <RequireAuth user={user} isLoading={isLoading} setShowLogin={setShowLogin}>
                            <UserDashboard user={user} />
                        </RequireAuth>}
                    />
                    <Route path="/library" element={
                        <RequireAuth user={user} isLoading={isLoading} setShowLogin={setShowLogin}>
                            <ModelLibrary user={user} />
                        </RequireAuth>}
                    />
                    <Route path="/models/:modelId" element={<ModelViewer />} />
                    <Route path="/device" element={<DeviceInfo />} />
                    <Route path="/calibration/:modelId" element={
                        <RequireAuth user={user} isLoading={isLoading} setShowLogin={setShowLogin}>
                            <Calibration user={user} />
                        </RequireAuth>
                    } />

                    <Route path="/userprofile" element={
                        <RequireAuth user={user} isLoading={isLoading} setShowLogin={setShowLogin}>
                            <UserProfile user={user} />
                        </RequireAuth>
                    } />

                    <Route path="/subscribe" element={
                        <RequireAuth user={user} isLoading={isLoading} setShowLogin={setShowLogin}>
                            <SubscribePlan user={user} />
                        </RequireAuth>
                    } />

                    <Route path="/editor" element={<Editor />} />
                    <Route path="/editor2" element={<MultiSplatEditor />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/capture-guide" element={<FAQ user={user} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />
                </Routes>
            </main>
        </div>
    );
}

function App() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Poppins:400,600', 'sans-serif']
            }
        });

        // Check authentication state
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setIsLoading(false);
            } else {
                setUser(null);
                setIsLoading(false);
            }
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const closeModal = () => setShowLogin(false);

    if (isLoading) {
        return <div>App Loading...</div>;
    }
    return (
        <BrowserRouter>
            <ErrorBoundary>
                <Layout user={user} isLoading={isLoading} setShowLogin={setShowLogin} />
                {showLogin && <LoginPage onClose={closeModal} />}
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
