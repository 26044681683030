import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import PyramidOutline from './PyramidCamera';

// import { Splat } from '@react-three/drei';
import { Splat } from 'SplatShader/Splat_v1';

const DotRenderer = ({ position, color, height }) => {
    return (
        <mesh position={position}>
            <boxGeometry args={[0.2, height, 0.2]} />
            <meshStandardMaterial color={color} />
        </mesh>
    );
};

// Render a box instead of a line
const LineRenderer = ({ line, color, thickness, height, opacity = 1 }) => {
    const { start, end } = line;
    const [mouseEnd, setMouseEnd] = useState(null);  // State to hold mouse position
    const boxRef = useRef();
    const { camera, gl, scene } = useThree();

    // Mouse movement handler to update mouse position
    const handleMouseMove = (event) => {
        const rect = gl.domElement.getBoundingClientRect();
        const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
        const mouseVector = new THREE.Vector3(x, y, 0).unproject(camera);

        // Raycast to ground plane at y = 0
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, camera);
        const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
        const intersection = new THREE.Vector3();
        raycaster.ray.intersectPlane(plane, intersection);

        if (intersection) {
            setMouseEnd(intersection.toArray());  // Update mouseEnd with intersection point
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, [camera]);

    useEffect(() => {
        if (!start) return;
        
        const startVector = new THREE.Vector3(...start);
        const endVector = end && end.length === 3 ? new THREE.Vector3(...end) : new THREE.Vector3(...(mouseEnd || start));

        const direction = new THREE.Vector3().subVectors(endVector, startVector);
        const length = direction.length();

        // Calculate the midpoint
        const midpoint = new THREE.Vector3().addVectors(startVector, endVector).multiplyScalar(0.5);

        // Create the box geometry with the calculated length and thickness
        const geometry = new THREE.BoxGeometry(thickness, height, length);
        const material = new THREE.MeshBasicMaterial({ color: color, transparent: true, opacity: opacity });
        const box = new THREE.Mesh(geometry, material);

        // Align the box with the direction vector
        box.position.set(midpoint.x, midpoint.y, midpoint.z);
        box.lookAt(endVector);  // Align the box to face the end point
        box.position.y += height / 2;

        boxRef.current = box;
        scene.add(box);

        return () => {
            scene.remove(box);
        };
    }, [start, end, mouseEnd, color, thickness, height, scene, opacity]);

    return null;
};

const LinesRenderer = ({ lines, color, thickness, height, opacity = 1 }) => {
    const linesDiv = lines.reduce((acc, point, index) => {
        if (index % 2 !== 0) {
            acc.push(
                <LineRenderer
                    key={`${index - 1}-${index}-height`}
                    line={{ start: lines[index - 1], end: point }}
                    color={color}
                    opacity={opacity}
                    thickness={thickness}
                    height={height}
                />
            );
            acc.push(
                <LineRenderer
                    key={`${index - 1}-${index}-base`}
                    line={{ start: lines[index - 1], end: point }}
                    color={color}
                    opacity={opacity + 0.3}
                    thickness={thickness}
                    height={0.1}
                />
            );
        }
        return acc;
    }, []);

    return (<>
        {linesDiv}
    </>);
};

const WallsRenderer = ({ walls, color, thickness, height, opacity }) => {
    if (!walls) { return null; }

    let flatWalls = walls.flat();
    let wallsList = flatWalls.map((point) => {
        return [point[0], 0, point[1]];
    });

    return (<>
        <LinesRenderer lines={wallsList} color={color} thickness={thickness} height={height} opacity={opacity} />
    </>);
};

const CamerasRenderer = ({ cameras, color = 0xffffff, opacity = 0.7, size = new THREE.Vector3(0.5, 0.5, 0.5) }) => {
    const { scene } = useThree();

    useEffect(() => {
        if (!cameras) return;

        const pyramids = cameras.map((camera) => {
            const pyramid = PyramidOutline(size, color, opacity);

            // Position the apex of the pyramid at the camera position
            pyramid.position.set(camera.position.x, camera.position.y, camera.position.z);

            // temp fix for camera lookAt
            if (camera.lookAt.y === 0) camera.lookAt.y = camera.position.y;

            // Calculate the direction vector from the camera position to the lookAt position
            const direction = new THREE.Vector3(
                camera.lookAt.x - camera.position.x,
                camera.lookAt.y - camera.position.y,
                camera.lookAt.z - camera.position.z
            ).normalize();

            // Calculate the quaternion for rotation
            const up = new THREE.Vector3(0, 1, 0); // Default up direction
            const quaternion = new THREE.Quaternion().setFromUnitVectors(up, direction);
            pyramid.quaternion.copy(quaternion);
            pyramid.rotateX(Math.PI);

            return pyramid;
        });

        pyramids.forEach((pyramid) => scene.add(pyramid));

        return () => {
            pyramids.forEach((pyramid) => scene.remove(pyramid));
        };
    }, [cameras, scene, color, opacity, size]);

    return null;
};

const PointCloudRenderer = ({ points, calibration, range, setLoading }) => {

    // console.log("Volume: Render Points");

    setLoading(true);

    const { position, color } = points;
    const { scale, rotation, translation } = calibration;

    // Create a BufferGeometry
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(position, 3));
    geometry.setAttribute('color', new THREE.BufferAttribute(color, 4));

    const material = useMemo(() => {
        return new THREE.PointsMaterial({
            size: 2,
            vertexColors: true,
            sizeAttenuation: false,
            clippingPlanes: [
                new THREE.Plane(new THREE.Vector3(1, 0, 0), -range.x[0]),
                new THREE.Plane(new THREE.Vector3(-1, 0, 0), range.x[1]),
                new THREE.Plane(new THREE.Vector3(0, 1, 0), -range.y[0]),
                new THREE.Plane(new THREE.Vector3(0, -1, 0), range.y[1]),
                new THREE.Plane(new THREE.Vector3(0, 0, 1), -range.z[0]),
                new THREE.Plane(new THREE.Vector3(0, 0, -1), range.z[1]),
            ],
            clipShadows: true,
        });
    }, [range]);

    setLoading(false);

    if (!geometry) { return null; }
    else {
        return (
            <points
                geometry={geometry}
                material={material}
                scale={scale}
                rotation={rotation}
                position={translation}
            ></points>
        );
    }
};

const PointCloudRendererSlow = ({ points, calibration, range, setLoading }) => {
    setLoading(true);
  
    const { position, color } = points;
    const { scale, rotation, translation } = calibration;
  
    const numPoints = position.length / 3;
  
    // Create the geometry and sort points based on distance from center
    const geometry = useMemo(() => {
      // Create an array of point indices and their distances squared from the center
      const pointsWithDistance = [];
      for (let i = 0; i < numPoints; i++) {
        const x = position[i * 3];
        const y = position[i * 3 + 1];
        const z = position[i * 3 + 2];
        const distanceSquared = x * x + y * y + z * z;
        pointsWithDistance.push({ index: i, distanceSquared });
      }
  
      // Sort the points by distance from the center
      pointsWithDistance.sort((a, b) => a.distanceSquared - b.distanceSquared);
  
      // Create new sorted position and color arrays
      const sortedPositions = new Float32Array(position.length);
      const sortedColors = new Float32Array(color.length);
  
      for (let i = 0; i < numPoints; i++) {
        const idx = pointsWithDistance[i].index;
        sortedPositions.set(position.subarray(idx * 3, idx * 3 + 3), i * 3);
        sortedColors.set(color.subarray(idx * 4, idx * 4 + 4), i * 4);
      }
  
      // Create the geometry and set the initial draw range to 0
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.BufferAttribute(sortedPositions, 3));
      geometry.setAttribute('color', new THREE.BufferAttribute(sortedColors, 4));
      geometry.setDrawRange(0, 0); // Start with zero points drawn
  
      return geometry;
    }, [position, color, numPoints]);
  
    // Use a ref to keep track of the start time
    const startTimeRef = useRef(null);
  
    // Desired total duration for the animation in seconds
    const totalDuration = 3; // Adjust this value to control the total animation duration
  
    // Update the draw range in each frame to animate the points appearing
    useFrame(({ clock }) => {
      if (startTimeRef.current === null) {
        startTimeRef.current = clock.getElapsedTime();
      }
  
      const elapsedTime = clock.getElapsedTime() - startTimeRef.current;
      const progress = Math.min(elapsedTime / totalDuration, 1); // Ensure progress doesn't exceed 1
  
      // Calculate the number of points to display based on progress
      const pointsToDisplay = Math.floor(progress * numPoints);
      geometry.setDrawRange(0, pointsToDisplay);
  
      // Once the animation is complete, we can stop updating
      if (progress === 1) {
        // Optionally, you can set loading to false here
      }
    });
  
    const material = useMemo(() => {
      return new THREE.PointsMaterial({
        size: 1,
        vertexColors: true,
        sizeAttenuation: false,
        clippingPlanes: [
          new THREE.Plane(new THREE.Vector3(1, 0, 0), -range.x[0]),
          new THREE.Plane(new THREE.Vector3(-1, 0, 0), range.x[1]),
          new THREE.Plane(new THREE.Vector3(0, 1, 0), -range.y[0]),
          new THREE.Plane(new THREE.Vector3(0, -1, 0), range.y[1]),
          new THREE.Plane(new THREE.Vector3(0, 0, 1), -range.z[0]),
          new THREE.Plane(new THREE.Vector3(0, 0, -1), range.z[1]),
        ],
        clipShadows: true,
      });
    }, [range]);
  
    setLoading(false);
  
    if (!geometry) return null;
  
    return (
      <points
        geometry={geometry}
        material={material}
        scale={scale}
        rotation={rotation}
        position={translation}
      ></points>
    );
  };

const SplatRenderer = ({ url, calibration, range = { x: [-999, 999], y: [-999, 999], z: [-999, 999] } }) => {
    const { scale, rotation, translation } = calibration;

    return (<>
        <Splat
            src={url}
            scale={scale}
            rotation={rotation}
            position={translation}
            range={range}
        />
    </>);
};

export {
    DotRenderer,
    LineRenderer,
    WallsRenderer,
    CamerasRenderer,
    PointCloudRenderer,
    PointCloudRendererSlow,
    SplatRenderer,
};