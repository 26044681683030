import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ModelControllerInstance from '../controllers/ModelController';
import userControllerInstance from 'controllers/UserController';
import SessionControllerInstance from 'controllers/SessionController';

import InteractiveModel from '../components/immersive/InteractiveModel';
import OrbitalModel from '../components/orbital/OrbitalModel';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const ModelViewer = () => {
    const { modelId } = useParams();
    const [modelConfig, setModelConfig] = useState(null);

    const [destination, setDestination] = useState(null);

    const [available, setAvailable] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchModelConfig = async (modelId) => {
        try {
            const config = await ModelControllerInstance.getModelById(modelId);
            const jobState = await userControllerInstance.checkJobAvailability(config.jobId);

            // console.log('Job state:', jobState);

            setAvailable(jobState.available);
            config.highResolution = jobState.highResolution;

            setModelConfig(config);
        } catch (error) {
            setError('Failed to fetch model configuration');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const createSession = async (modelId) => {
        // fetch user ip address
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            const session = await SessionControllerInstance.createSession({
                _id: uuidv4(),
                visitorIP: response.data.ip,
                modelId: modelId,
            });
        } catch (error) {
            console.error('Failed to log model session:', error);
        }
    }

    useEffect(() => {
        fetchModelConfig(modelId);
        createSession(modelId);
    }, [modelId]);

    const openPortal = (portal) => {
        setModelConfig(null);
        setLoading(true);
        fetchModelConfig(portal.destination.modelId);
        setDestination(portal.destination);
    };

    if (loading) {
        return <div>Config Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!modelConfig) {
        return <div>Model not found</div>;
    }

    if (!available) {
        return <div>Not available</div>;
    }

    if (modelConfig.interaction === 'FirstPerson') {
        return <InteractiveModel config={modelConfig} openPortal={openPortal} destination={destination} />;
    } else if (modelConfig.interaction === 'Orbital') {
        return <OrbitalModel config={modelConfig} />;
    }
};

export default ModelViewer;