import React from 'react';
import Header from '../components/Header';
import MissionStatement from '../components/art/MissionStatement';
import SampleSpace from '../components/art/SampleSpace';
import Description from 'components/art/Description';
import CoreValue from '../components/art/CoreValue';
import TechAdvantage from '../components/art/TechAdvantage';
import BuildCTA from '../components/demo/BuildCTA';
import Footer from '../components/demo/Footer';

const ArtLandingPage = ({ user }) => {

  return (
    <div style={{
      width: '100%',
    }}>
        <Header user={user} />
        <MissionStatement />
        <Description />
        <SampleSpace />
        <CoreValue />
        {/* <TechAdvantage /> */}
        <BuildCTA user={user} />
        <Footer />
    </div>
  );
};

export default ArtLandingPage;
