import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GeneralSettingController from './controllers/GeneralSettingController';
import CollisionController from './controllers/CollisionController';
import CalibrateController from './controllers/CalibrationController';
import ViewPoint from './controllers/ViewPointController';
import ViewHeightController from './controllers/ViewHeightController';
import { BorderColor } from '@mui/icons-material';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ControllBar = ({
    userInfo,
    info, setInfo,

    splatUrl,
    step, handleSetStep,

    axis, handleSetAxis,
    showBlob, toggleShowBlob,
    range, handleSetRange,
    applyRange, setApplyRange,
    percentage, handleSetPercentage,

    calibration,
    translation, setTranslation,
    rotation, setRotation,
    scale, setScale,

    showScale, toggleShowScale,
    distance, setActualDistance,
    startScale, toggleStartScale,
    clearLine, scaleModel, setLine,

    walls, setWalls, clearWalls, removePreviousWall,
    addWall, toggleAddWall, editWall, toggleEditWall,
    selectedWall, removeSelectedWall,
    wallHeight, setWallHeight,

    cameras, setCameras,
    addCamera, toggleAddCamera,

    interaction, setInteraction,

    handleReset, handleSave, disableSave,
}) => {

    const [steps, setSteps] = useState([0, 1, 2, 3]);
    useEffect(() => {
        if (interaction === 'FirstPerson') setSteps([0, 1, 2, 3]);
        if (interaction === 'Orbital') setSteps([0, 1]);
    }, [interaction]);

    const navigate = useNavigate();

    const handlePrevious = () => {
        if (step > 0) {
            const prevStep = step - 1;
            handleSetStep(prevStep);
        }
    };

    const handleNextButton = () => {
        if (step < 3) {
            const nextStep = step + 1;
            handleSetStep(nextStep);
        }
    };

    const handleSaveButton = async () => {
        if (!disableSave) {
            await handleSave();
        } else {
            alert('Congradulations! You finished the editor play ground. Now create your first 3D tour!');
            navigate(-1);
        }
    };

    const buttonStyle = {
        border: '1px solid #000',
        borderRadius: '4px',
        width: '100%',
        height: '40px',
        textAlign: 'center',
        lineHeight: '40px',
        cursor: 'pointer',
        fontWeight: 'bold',
        fontFamily: '"Poppins", sans-serif',
        backgroundColor: 'transparent',
        color: '#FDFDFD',
        borderColor: '#3366FF',
        transition: 'background-color 0.3s ease, color 0.3s ease',
    };

    const previousNextButtonStyle = {
        ...buttonStyle,
        width: '48%',
        backgroundColor: '#3366FF',
        fontFamily: '"Poppins", sans-serif',
        color: '#fff',
    };

    const disabledButtonStyle = {
        ...buttonStyle,
        width: '48%',
        cursor: 'not-allowed',
        backgroundColor: '#ccc',
        fontFamily: '"Poppins", sans-serif',
        color: '#fff',
    };

    const styles = {
        returnButton: {
            position: 'absolute',
            top: '10px',
            left: '10px',
            padding: '5px 10px',
            zIndex: 1000,
        },
        controlBar: {
            position: 'absolute',
            top: '5vh',
            right: '15px',
            width: '340px',
            height: '90vh',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '0',
            margin: '0',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            zIndex: 1000,
        },
        progressBar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '300px',
            height: '70px',
            padding: '20px 0',
        },
        contentBar: {
            flexGrow: 1,
            width: '100%',
            height: '100%',
            paddingRight: '15px',
            marginLeft: '15px',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        bottomBar: {
            width: '300px',
            height: '100px',
            padding: '20px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        progressStep: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            flex: 1,
            cursor: 'pointer',
        },
        progressCircle: {
            width: '20px',
            height: '20px',
            backgroundColor: '#ffffff',
            border: '2px solid #333',
            borderRadius: '50%',
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        activeProgressCircle: {
            backgroundColor: '#3366FF',
            border: '2px solid #3366FF',
        },
        completedCircleBefore: {
            content: "''",
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '10px',
            height: '10px',
            backgroundColor: '#fff',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
        },
        progressLabel: {
            fontSize: '14px',
            fontFamily: '"Poppins", sans-serif',
            color: '#fff',
            marginTop: '10px',
            textAlign: 'center',
        },
        progressText: {
            fontSize: '10px',
            color: '#fff',
            textAlign: 'center',
            fontFamily: '"Poppins", sans-serif',
        },
        progressLine: {
            height: '2px',
            backgroundColor: '#333',
            position: 'absolute',
            top: '25%',
            left: '0',
            width: '100%',
            zIndex: 0,
            transform: 'translateY(-50%)',
        },
    };

    return (
        <div>
            <div>
                <IconButton
                    color="primary"
                    style={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        width: '48px',
                        height: '48px',
                        color: 'black',
                        backgroundColor: 'rgba(255, 255, 255, 0.85)',
                        border: '2px solid #000',
                        zIndex: 1000
                    }}
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
            </div>
            <div style={styles.controlBar}>
                {step >= 0 && <div style={styles.progressBar}>
                    {/* <div style={styles.progressLine}></div> */}
                    {steps.map((viewStep) => (
                        <div
                            key={viewStep}
                            style={{
                                ...styles.progressStep,
                            }}
                            onClick={() => handleSetStep(viewStep)}
                        >
                            {step === viewStep ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                            <div style={styles.progressLabel}>Step {viewStep}</div>
                        </div>
                    ))}
                </div>}

                <div style={styles.contentBar}>
                    <div style={{
                        width: '300px',
                        height: '100%',
                        padding: '0 20px',
                    }}>
                        {step > 0 && interaction === 'FirstPerson' && <div>
                            <ViewHeightController range={range} handleSetRange={handleSetRange} />

                            <hr style={{
                                width: '100%',
                                border: '0',
                                borderTop: '5px solid #444',
                                margin: '10px 0',
                                borderColor: 'gray',
                            }} />
                        </div>}

                        <div style={{
                            padding: '10px 0',
                            height: '100%',
                        }}>
                            {step === 0 && <GeneralSettingController
                                info={info} setInfo={setInfo}
                                interaction={interaction} setInteraction={setInteraction}
                            />}

                            {step === 1 && <CalibrateController
                                showBlob={showBlob} toggleShowBlob={toggleShowBlob}
                                axis={axis} handleSetAxis={handleSetAxis}
                                translation={translation} setTranslation={setTranslation}
                                rotation={rotation} setRotation={setRotation}
                                scale={scale} setScale={setScale}
                                distance={distance} setActualDistance={setActualDistance}
                                scaleModel={scaleModel} clearLine={clearLine}
                                startScale={startScale} toggleStartScale={toggleStartScale}
                                showScale={showScale} toggleShowScale={toggleShowScale}
                                cameras={cameras} setCameras={setCameras}
                                walls={walls} setWalls={setWalls}
                                range={range} handleSetRange={handleSetRange}
                                applyRange={applyRange} setApplyRange={setApplyRange}
                                interaction={interaction}
                            />}

                            {(step === 2) && <ViewPoint
                                userInfo={userInfo}
                                handleSetAxis={handleSetAxis}
                                cameras={cameras} setCameras={setCameras}
                                addCamera={addCamera} toggleAddCamera={toggleAddCamera}
                                splatUrl={splatUrl} calibration={calibration}
                                showBlob={showBlob} toggleShowBlob={toggleShowBlob}
                            />}

                            {step === 3 && <CollisionController
                                setLine={setLine}
                                axis={axis} handleSetAxis={handleSetAxis}
                                walls={walls}
                                addWall={addWall} toggleAddWall={toggleAddWall}
                                clearWalls={clearWalls} removePreviousWall={removePreviousWall}
                                selectedWall={selectedWall}
                                editWall={editWall} toggleEditWall={toggleEditWall}
                                removeSelectedWall={removeSelectedWall}
                                wallHeight={wallHeight} setWallHeight={setWallHeight}
                                showBlob={showBlob} toggleShowBlob={toggleShowBlob}
                            />}
                        </div>
                    </div>
                </div>

                <div style={styles.bottomBar}>
                    {step > 0 && <div>
                        <div
                            style={{
                                ...buttonStyle,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={handleReset}
                        >
                            Reset
                            <RestartAltIcon style={{ marginLeft: '3px' }} />
                        </div>
                    </div>}

                    <br />

                    <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'space-between' }}>
                        {step === 0 ? (
                            <div
                                style={{
                                    ...previousNextButtonStyle,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={handleSaveButton}
                            >
                                Save
                                <SaveIcon style={{ marginLeft: '3px' }} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    ...previousNextButtonStyle,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={handlePrevious}
                            >
                                <NavigateBeforeIcon />
                                Back
                            </div>
                        )}

                        {}

                        <div
                            style={{
                                ...previousNextButtonStyle,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={step < steps.length - 1 ? handleNextButton : handleSaveButton}
                        >
                            {step < steps.length - 1 ? 'Next' : 'Save'}
                            {step < steps.length - 1 ? <NavigateNextIcon /> : <SaveIcon style={{ marginLeft: '3px' }} />}
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
};

export default ControllBar;
