import React, { useState, useEffect } from 'react';
import { Grid, OrbitControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import Spinner from 'components/Spinner';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { SplatRenderer } from 'components/immersive/SplatRenderer';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const iconButtonMargin = 20;

const styles = {
    progressBarContainer: {
        height: '20px',
        width: '300px',
        backgroundColor: '#ddd',
        borderRadius: '4px',
        overflow: 'hidden',
        marginBottom: '20px',
        position: 'relative',
    },
    progressBar: (percentage) => ({
        height: '100%',
        width: `${percentage}%`,
        backgroundColor: '#3366FF',
    }),
    progressText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#000',
        fontSize: isMobile ? '10px' : '14px',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 'bold',
    },
    sidebarContainer: {
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        zIndex: 2000,
        display: 'flex',
        flexDirection: 'row',
    },
    iconButtonsContainer: (isAnySidebarOpen, sidebarWidth) => ({
        position: 'fixed',
        top: '20px',
        right: isAnySidebarOpen ? `${sidebarWidth + iconButtonMargin}px` : `${iconButtonMargin}px`,
        zIndex: 2001,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }),
    sidebar: (sidebarWidth, showSidebar) => ({
        width: `${sidebarWidth}px`,
        backgroundColor: '#333',
        color: '#fff',
        transition: 'transform 0.5s ease-in-out',
        transform: showSidebar ? 'translateX(0)' : 'translateX(100%)',
    }),
    iconButton: {
        width: '48px',
        height: '48px',
        color: 'black',
        marginBottom: '20px',
    },
    userGuideIconButton: {
        width: '40px',
        height: '40px',
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        marginBottom: '20px',
    },
    arrowBack: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        width: '48px',
        height: '48px',
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        zIndex: 2001,
    }
};

const CameraUpdater = () => {
    const { camera, set } = useThree();

    const position = [3, 2, 3];
    const lookAt = [0, 2, 0];

    useEffect(() => {
        const setCameraPosition = () => {
            camera.position.set(...position);
            camera.lookAt(...lookAt);
        };

        setCameraPosition();
    }, [camera]);

    return null;
};

const OrbitalModel = ({ config }) => {

    const [loading, setLoading] = useState(true);
    const [percent, setPercent] = useState(0);

    const navigate = useNavigate();

    const handleLoading = (percent) => {
        if (percent) {
            setPercent(percent.toFixed());
            if (percent === 100) {
                setLoading(false);
            }
        }
    };

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100dvh',
                width: '100vw',
                margin: '0',
                top: '0',
                left: '0',
                backgroundColor: '#fff',
                color: '#fff',
            }}
        >
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                >
                    <div style={styles.progressBarContainer}>
                        <div style={styles.progressBar(percent)}></div>
                        <div style={styles.progressText}>{percent}%</div>
                    </div>
                </div>
            )}

            <div
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <Canvas>
                    <SplatRenderer
                        config={config}
                        loading={loading}
                        handleLoading={handleLoading}
                        backgroundColor='rgba(255, 255, 255, 0)'
                    />

                    <CameraUpdater />

                    <OrbitControls
                        enableRotate={true}
                        minPolarAngle={0}
                        maxPolarAngle={Math.PI / 2}
                    />
                </Canvas>

                <IconButton
                    color="primary"
                    style={styles.arrowBack}
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default OrbitalModel;