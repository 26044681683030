import React, { useState, useRef, useEffect } from 'react';
import ModelControllerInstance from '../../controllers/ModelController';
import AssistantIcon from '@mui/icons-material/Assistant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SidebarHeader from './SidebarHeader';

const VirtualTourGuide = ({ onCameraData, chatHistory, setChatHistory, onClose }) => {
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (chatHistory.length === 0) {
      const initialMessage = {
        role: 'assistant',
        content:
          "Hello, I’m Kaleida! 👋 I’m your personal guide for this home. How can I help you?",
      };
      setChatHistory([initialMessage]);
    }
  }, [chatHistory, setChatHistory]);

  // Create a ref for the chat history container
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleInputSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    setLoading(true);
    const newChatHistory = [...chatHistory, { role: 'user', content: userInput }];
    setChatHistory(newChatHistory);

    try {
      const chatHistoryForAPI = newChatHistory.slice(1);
      const data = {
        userInput: {
          text: userInput,
          floorPlanURL: 'https://s3.amazonaws.com/kaleida.space/assets/kaufman-floorplan.png',
          modelId: '03afe4e7-16d8-4728-a908-459383292fdb',
        },
        chatHistory: chatHistoryForAPI,
      };

      const response = await ModelControllerInstance.getVirtualGuideResponse(data);
      let camera = null;
      let assistantMessage = '';

      if (response) {
        const assistantData = response;
        if (assistantData.content) {
          assistantMessage = assistantData.content;
        } else if (assistantData.tool_calls) {
          const functionCall = assistantData.tool_calls[0].function;
          const argumentsObj = JSON.parse(functionCall.arguments);

          if (argumentsObj.camera) camera = argumentsObj.camera;
          if (argumentsObj.explanation) assistantMessage = argumentsObj.explanation;
        }

        setChatHistory(prevChatHistory => [
          ...prevChatHistory,
          { role: 'assistant', content: assistantMessage },
        ]);

        if (camera && onCameraData) onCameraData(camera);
      } else {
        assistantMessage = 'An error occurred while processing your request.';
        setChatHistory(prevChatHistory => [
          ...prevChatHistory,
          { role: 'assistant', content: assistantMessage },
        ]);
      }

      setUserInput('');
    } catch (error) {
      console.error('Failed to get virtual guide response:', error);
      setChatHistory(prevChatHistory => [
        ...prevChatHistory,
        { role: 'assistant', content: 'An error occurred while processing your request.' },
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%',
        backgroundColor: '#333',
        color: '#fff',
        zIndex: 2000,
        transition: 'transform 0.3s ease-in-out',
        transform: 'translateX(0)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Chat Header */}
      <SidebarHeader
        title="Virtual Tour Guide"
        onBack={onClose}
        icon={<AssistantIcon style={{ fontSize: '24px', color: '#fff' }} />}
      />

      {/* Chat History */}
      <div
        ref={chatHistoryRef}
        style={{
          flex: 1,
          padding: '10px',
          overflowY: 'auto',
          backgroundColor: '#444',
        }}
      >
        {chatHistory.map((message, index) => (
          <div
            key={index}
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
            }}
          >
            <div
              style={{
                maxWidth: '70%',
                padding: '8px 12px',
                borderRadius: '15px',
                backgroundColor: message.role === 'user' ? '#DCF8C6' : '#FFFFFF',
                color: 'black',
                wordWrap: 'break-word',
              }}
            >
              {message.content}
            </div>
          </div>
        ))}
      </div>

      {/* Input Form */}
      <form onSubmit={handleInputSubmit} style={{ display: 'flex', padding: '10px', backgroundColor: '#444' }}>
        <input
          type="text"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message..."
          style={{
            flex: 1,
            padding: '10px',
            border: 'none',
            outline: 'none',
            fontSize: '14px',
            borderRadius: '5px',
          }}
          disabled={loading}
        />
        <button
          type="submit"
          style={{
            padding: '0 16px',
            backgroundColor: '#1976d2',
            border: 'none',
            color: 'white',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
          }}
          disabled={loading || !userInput.trim()}
        >
          {loading ? '...' : 'Send'}
        </button>
      </form>
    </div>
  );
};

export default VirtualTourGuide ;
