import axios from 'axios';
import { auth } from '../components/authentication/firebase';

// Create an Axios instance

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    timeout: 300000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

// const api = axios.create({
//     baseURL: 'http://localhost:8080/api/v1',
//     timeout: 300000,
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });

// Add a request interceptor
api.interceptors.request.use(async function (config) {
    // Get the current user from Firebase Authentication
    const currentUser = auth.currentUser;

    if (currentUser) {
        // Get the ID token from Firebase
        const token = await currentUser.getIdToken(true);
        if (token) {
            // Attach the token to the Authorization header
            config.headers.Authorization = `Bearer ${token}`;
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
});

export default api;
