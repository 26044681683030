// src/models/UserModel.js
import api from 'api/axiosConfig';

class UserModel {
  async createUser(_id, email, name) {
    try {
      const response = await api.post('/user/', { _id, email, name });
      return response.data;
    } catch (error) {
      console.error('Failed to create user:', error);
      throw error;
    }
  }

  async getUserById(_id) {
    try {
      const response = await api.get(`/user?userId=${_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user:', error);
    }
  }

  async getUserJobsByUserId(_id) {
    try {
      const response = await api.get(`/user/get-user-jobs?userId=${_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user jobs:', error);
    }
  }

  async getUserModelsByUserId(_id) {
    try {
      const response = await api.get(`/user/get-user-models?userId=${_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user models:', error);
    }
  }

  async getUserJobsWithThumbnailByUserId(_id) {
    try {
      const response = await api.get(`/user/get-user-jobs-with-thumbnail?userId=${_id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user jobs:', error);
    }
  }

  async getUserByEmail(email) {
    try {
      const response = await api.get(`/user/email?email=${email}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get user:', error);
    }
  }

  async updateUser(_id, data) {
    try {
      const response = await api.put(`/user?userId=${_id}`, data);
      return response.data;
    } catch (error) {
      console.error('Failed to update user:', error);
      throw error;
    }
  }

  async updateBulkUsername(_id, data) {
    try {
      const response = await api.put(`/user/bulk-update-job-user-name?userId=${_id}`, data);
      return response.data;
    } catch (error) {
      console.error('Failed to update user:', error);
      throw error;
    }
  }

  async deleteUserByEmail(email) {
    try {
      const response = await api.delete(`/user/email?email=${email}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  }

  async addToEmailList(email) {
    try {
      const response = await api.post(`/emailList?email=${email}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  async getSubscriptionPlan(product) {
    try {
      const response = await api.post(`/user/get-subscription-plan?productName=${product}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get subscription plan:', error);
    }
  }

  async createCheckoutSession(info) {
    try {
      const response = await api.post('/user/create-checkout-session', info);
      return response.data;
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      throw error;
    }
  }

  async createCheckoutPortalSession(sessionId) {
    try {
      const response = await api.post(`/user/create-checkout-portal-session?sessionId=${sessionId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to create portal session:', error);
      throw error;
    }
  }

  async createBillingPortalSession(subscriptionId) {
    try {
      const response = await api.post(`/user/create-billing-portal-session?subscriptionId=${subscriptionId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to create billing portal session:', error);
      throw error;
    }
  }

  async checkUserSubscriptionPlan(subscriptionId) {
    try {
      const response = await api.get(`/user/check-subscription-plan?subscriptionId=${subscriptionId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to get subscription plan:', error);
    }
  }

  async checkUserFeatureFlag(userId, flag) {
    try {
      const response = await api.post("/user/feature-flags", { userId: userId, flag: flag });
      return response.data;
    } catch (error) {
      console.error('Failed to get user feature flag:', error);
    }
  }

  async checkJobAvailability(jobId) {
    try {
      const response = await api.get(`/user/check-job-availability?jobId=${jobId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to check job availability:', error);
    }
  }
}

const userModelInstance = new UserModel();
export default userModelInstance;
