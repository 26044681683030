// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222;
  color: #fff;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x : hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,UAAU;EACV,SAAS;EACT,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ","sourcesContent":["body,html {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #222;\n  color: #fff;\n  width: 100%;\n  padding: 0;\n  margin: 0;\n  overflow-x : hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100dvh;\n  width: 100vw;\n  box-sizing: border-box;\n  overflow-x: hidden;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
