// src/controllers/AuthController.js
import UserModel from '../models/UserModel';

class UserController {
  constructor(userModel) {
    this.userModel = userModel;
  }

  async handleAuthentication(result, provider, navigate, closeModal) {
    const user = result.user;
  
    const userDisplayName = user.displayName;
    const userEmail = user.email;
    try {
      const existingUser = await this.userModel.getUserByEmail(user.email);
      if (existingUser) {
        closeModal();
        navigate('/');
        console.log('Welcome back!')
      } else {
        await this.userModel.createUser(user.uid, userEmail, userDisplayName);
        closeModal();
        navigate('/signup-form');
        console.log('User created successfully!')
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }
  async getUserById(userId) {
    try {
      const user = await this.userModel.getUserById(userId);
      if (user.subscription && user.subscription.subscriptionId) {
        const subscriptionState = await this.userModel.checkUserSubscriptionPlan(user.subscription.subscriptionId);
        user.subscription = {
          subscriptionId: user.subscription.subscriptionId,
          plan: subscriptionState.plan,
          status: subscriptionState.status,
        };
      }
      return user;
    } catch (error) {
      console.error('Error getting user:', error);
    }
  }

  async getUserJobsByUserId(userId) {
    try {
      const user = await this.userModel.getUserJobsByUserId(userId);
      if (user.subscription && user.subscription.subscriptionId) {
        const subscriptionState = await this.userModel.checkUserSubscriptionPlan(user.subscription.subscriptionId);
        user.subscription = {
          subscriptionId: user.subscription.subscriptionId,
          plan: subscriptionState.plan,
          status: subscriptionState.status,
        };
      }
      return user;
    } catch (error) {
      console.error('Error getting user jobs:', error);
    }
  }

  async getUserModelsByUserId(userId) {
    try {
      const user = await this.userModel.getUserModelsByUserId(userId);
      if (user.subscription && user.subscription.subscriptionId) {
        const subscriptionState = await this.userModel.checkUserSubscriptionPlan(user.subscription.subscriptionId);
        user.subscription = {
          subscriptionId: user.subscription.subscriptionId,
          plan: subscriptionState.plan,
          status: subscriptionState.status,
        };
      }
      return user;
    } catch (error) {
      console.error('Error getting user jobs:', error);
    }
  }

  async getUserJobsWithThumbnailByUserId(userId) {
    try {
      const user = await this.userModel.getUserJobsWithThumbnailByUserId(userId);
      if (user.subscription && user.subscription.subscriptionId) {
        const subscriptionState = await this.userModel.checkUserSubscriptionPlan(user.subscription.subscriptionId);
        user.subscription = {
          subscriptionId: user.subscription.subscriptionId,
          plan: subscriptionState.plan,
          status: subscriptionState.status,
        };
      }
      return user;
    } catch (error) {
      console.error('Error getting user jobs:', error);
    }
  }

  async getUserByEmail(email) {
    try {
      const user = await this.userModel.getUserByEmail(email);
      if (user.subscription && user.subscription.subscriptionId) {
        const subscriptionState = await this.userModel.checkUserSubscriptionPlan(user.subscription.subscriptionId);
        user.subscription = {
          subscriptionId: user.subscription.subscriptionId,
          plan: subscriptionState.plan,
          status: subscriptionState.status,
        };
      }
      return user;
    } catch (error) {
      console.error('Error getting user:', error);
    }
  } 

  async updateUserById(userId, userData) {
    try {
      const updatedUser = await this.userModel.updateUser(userId, userData);
      return updatedUser;
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  async updateBulkUsernameById(userId, userData) {
    try {
      const updatedUser = await this.userModel.updateBulkUsername(userId, userData);
      return updatedUser;
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  async addToEmailList(email) {
    try {
      const response = await this.userModel.addToEmailList(email);
      return response;
    } catch (error) {
      return error;
    }
  }

  async checkUserJobs (userId) {
    const setJobLimit = (user) => {
      if (!user.subscription) {
        return 3;
      } else {
        if (user.subscription.status !== 'active') {
          return 3;
        } else {
          switch (user.subscription.plan) {
            case "Essential":
              return 10;
            case "Premium":
              return 20;
            default:
              return 3;
          }
        }
      }
    };
    
    try {
      const user = await this.userModel.getUserJobsByUserId(userId);

      if (user.subscription && user.subscription.subscriptionId) {
        const subscriptionState = await this.userModel.checkUserSubscriptionPlan(user.subscription.subscriptionId);
        user.subscription = {
          subscriptionId: user.subscription.subscriptionId,
          plan: subscriptionState.plan,
          status: subscriptionState.status,
        };
      }

      if (user.role === 'admin') {
        if (
          user.subscription &&
          user.subscription.status === 'active' &&
          user.subscription.plan === 'Capybara'
        ) {
          // return user.jobs.length >= 5;
          return false;
        } else {
          return false;
        }
      }

      const jobs = user.jobs;
      const ongoingJobs = jobs.filter(job => job.status !== 'Completed');
      const completedJobs = jobs.filter(job => job.status === 'Completed');

      const jobLimit = setJobLimit(user);
      // console.log('Job limit:', jobLimit);

      if (ongoingJobs.length > 0 || completedJobs.length >= jobLimit) {
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking user jobs:', error);
      return false;
    }
  };

  async getSubscriptionPlan (product) {
    try {
      const response = await this.userModel.getSubscriptionPlan(product);
      return response;
    } catch (error) {
      console.error('Error getting subscription plan:', error);
    }
  }

  async createCheckoutSession (info) {
    try {
      const response = await this.userModel.createCheckoutSession(info);
      return response;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  async createCheckoutPortalSession (sessionId) {
    try {
      const response = await this.userModel.createCheckoutPortalSession(sessionId);
      return response;
    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  }

  async createBillingPortalSession (subscriptionId) {
    try {
      const response = await this.userModel.createBillingPortalSession(subscriptionId);
      return response;
    } catch (error) {
      console.error('Error creating billing portal session:', error);
    }
  }

  async checkUserFeatureFlag(userId, flag) {
    try {
      const response = await this.userModel.checkUserFeatureFlag(userId, flag);
      return response;
    } catch (error) {
      console.error('Error checking user feature flag:', error);
    }
  }

  async checkJobAvailability(jobId) {
    try {
      const response = await this.userModel.checkJobAvailability(jobId);
      return response;
    } catch (error) {
      console.error('Error checking job availability:', error);
    }
  }
}

const userControllerInstance = new UserController(UserModel);
export default userControllerInstance;  // Passing the UserModel instance