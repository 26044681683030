import React from 'react';
import { Link } from 'react-router-dom';
import icon from 'asset/kaleida_icon.png';

const styles = {
    card: {
        color: '#fff',
        width: '100%',
        maxWidth: '320px',
        height: '100%',
        boxSizing: 'border-box',
        transition: 'transform 0.2s ease-in-out',
    },
    cardHover: {
        transform: 'scale(1.05)',
    },
    image: {
        width: '100%',
        aspectRatio: '8/5',
        objectFit: 'cover',
        borderRadius: '8px',
        cursor: 'pointer',
        backgroundColor: '#fff',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align to the left
        width: '100%',
        gap: '0',
        margin: '5px 0',
    },
    name: {
        fontSize: '20px',
        color: '#fff',
        wordBreak: 'break-word',
        margin: '0'
    },
    username: {
        fontSize: '16px',
        color: '#fff',
        wordBreak: 'break-word',
        margin: '0'
    },
};

const Card = ({ job }) => {

    return (<>
        {job.status === 'Completed' && job.modelName &&
            <div
                key={job._id}
                style={styles.card}
                onMouseEnter={(e) => e.currentTarget.style.transform = styles.cardHover.transform}
                onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}
            >
                <Link to={`/models/${job.modelId}`}>
                    <img src={job.modelThumbnail ? job.modelThumbnail : icon} alt="Model Thumbnail" style={styles.image} />
                </Link>
                <div style={styles.info}>
                    <p style={styles.name}>{job.modelName}</p>
                    <p style={styles.username}>{job.userName ? `@${job.userName}` : '@Kaleida'}</p>
                </div>
            </div>
        }
    </>);
};

export default Card;
