import React, { useState, useEffect } from 'react';
import empowerment from 'asset/empowerment.jpg';
import accessibility from 'asset/national-cancer-institute.jpg';
import education from 'asset/education.jpg';
import preservation from 'asset/preservation.jpg';

const CoreValue = () => {
  // State to track if the device is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    featuresSection: {
      fontFamily: 'sans-serif',
      color: 'black',
      padding: isMobile ? '2%' : '5%',
      width: '100%',
      boxSizing: 'border-box',
      flexGrow: 1,
    },
    header: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: isMobile ? '30px' : '48px',
      fontWeight: '400',
      textAlign: 'center',
      marginBottom: isMobile ? '3%' : '5%',
      color: '#ffffff',
    },
    featuresGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
      gap: '2%',
      width: '100%',
      boxSizing: 'border-box',
    },
    featureCard: {
      borderRadius: '10px',
      textAlign: 'left',
      backgroundColor: 'black',
      padding: '2%',
      boxSizing: 'border-box',
    },
    icon: {
      width: '100%',
      height: 'auto',
      borderRadius: '10px',
      marginBottom: isMobile ? '5%' : '2%',
      maxWidth: isMobile ? '70%' : '100%',
    },
    cardTitle: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: isMobile ? '20px' : '30px',
      fontWeight: '600',
      marginBottom: isMobile ? '0.5em' : '1em',
      color: '#ffffff',
    },
    cardDescription: {
      fontFamily: '"Poppins", sans-serif',
      fontWeight: '400',
      fontSize: isMobile ? '20px' : '20px',
      color: '#ffffff',
      opacity: 0.5,
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5%',
      backgroundColor: 'black',
      color: 'black',
      width: '100%',
      boxSizing: 'border-box',
      minHeight: '100vh',
    },
  };

  const FeatureCard = ({ icon, title, description }) => (
    <div style={styles.featureCard}>
      <div>
        <img src={icon} alt={title} style={styles.icon} />
      </div>
      <h3 style={styles.cardTitle}>{title}</h3>
      <p style={styles.cardDescription}>{description}</p>
    </div>
  );

  return (
    <section style={styles.section}>
      <div style={styles.featuresSection}>
        <h2 style={styles.header}>Our Core Values</h2>
        <div style={styles.featuresGrid}>
          <FeatureCard
            icon={empowerment}
            title="Empowerment Through Technology"
            description="We harness cutting-edge innovation to enable artists and institutions to realize their full potential."
          />
          <FeatureCard
            icon={accessibility}
            title="Accessibility for All"
            description="We believe everyone should have access to art, regardless of physical, geographical, or technological barriers."
          />
          <FeatureCard
            icon={education}
            title="Education & Growth"
            description="We are committed to using art and technology to inspire learning, curiosity, and cultural understanding."
          />
          <FeatureCard
            icon={preservation}
            title="Culture Preservation"
            description="We strive to protect and share diverse cultural heritage through digital art, ensuring it is accessible for future generations."
          />
        </div>
      </div>
    </section>
  );
};

export default CoreValue;
