
import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import aspace from 'asset/aspace_thumbnail.png';
import galley from 'asset/gallery_thumbnail.png';

const SampleSpace = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  const navigate = useNavigate();

  const styles = {
    sectionBackground: {
      position: 'relative',
      backgroundColor: '#000',
      padding: '100px 20px',
    },
    contentWrapper: {
      position: 'relative',
      zIndex: 1,
      maxWidth: '80%',
      margin: '0 auto',
    },
    description: {
      textAlign: 'center',
      fontSize: isMobile ? '16px' : '18px', // Responsive font size for mobile
      color: '#CCCCCC',
      marginBottom: '50px',
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
        gap: isMobile ? '20px' : '5%',
        padding: isMobile ? '0 20px' : '0',
      },
    card: {
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
        transition: 'transform 0.3s ease-in-out',
    },
    cardHover: {
      transform: 'scale(1.02)',
    },
    imageContainer: {
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    overlay: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      background: 'rgba(0,0,0,0.7)',
      color: '#fff',
      padding: '20px',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    overlayVisible: {
      opacity: 1,
    },
    cardTitle: {
        fontSize: isMobile ? '18px' : '24px',
        marginBottom: '10px',
        fontWeight: 600,
        fontFamily: 'Poppins, sans-serif',
      },
      cardDescription: {
        fontSize: isMobile ? '14px' : '16px',
        opacity: 0.8,
        fontFamily: 'Poppins, sans-serif',
      },
  };


  const handleImageClick = (modelId) => {
    navigate(`/models/${modelId}`);
  };

  const WorkCard = ({ icon, title, description, modelId }) => {
    const [isHovered, setIsHovered] = React.useState(false);

    return (
      <div
        style={{ ...styles.card, ...(isHovered ? styles.cardHover : {}) }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => handleImageClick(modelId)}
      >
        <img src={icon} alt={title} style={styles.image} />
        <div style={{ ...styles.overlay, ...((isHovered || isMobile) ? styles.overlayVisible : {}) }}>
          <div style={styles.cardTitle}>{title}</div>
          <div style={styles.cardDescription}>{description}</div>
        </div>
      </div>
    );
  };

  return (
    <div style={styles.sectionBackground}>
      <div style={styles.contentWrapper}>
        <div style={styles.gridContainer}>
          <WorkCard
            icon={galley}
            title="VillageOneArt Gallery"
            description="A women-led contemporary art space in the neighborhood of Chelsea, NYC"
            modelId="dfd129c6-e0e5-4ada-808d-ebfaf3fe978e"
          />
          <WorkCard
            icon={aspace}
            title="A Space Art Gallery"
            description="A cutting-edge and creatively rich contemporary art gallery in Brooklyn, NYC"
            modelId="3633f044-3ae5-4a9c-8afb-441523f5f6e0"
          />
        </div>
      </div>
    </div>
  );
};

export default SampleSpace;
