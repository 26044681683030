import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import ContactUs from '../components/demo/ContactUs';
import userControllerInstance from 'controllers/UserController';
import subscribeImg from '../asset/subscribeImg.svg';
import Footer from '../components/demo/Footer';
import CheckIcon from '@mui/icons-material/Check';

const styles = {
    page: {
    },
    container: {
        paddingTop: '500px',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
        backgroundColor: 'black',
        padding: '0 20px',
        boxSizing: 'border-box',
        fontFamily: 'Poppins, sans-serif',
    },
    headerSection: {
        paddingTop: '100px',
        textAlign: 'center',
        color: 'white',
    },
    headerTitle: {
        fontSize: '48px',
        fontWeight: '700',
        lineHeight: '1.2',
        margin: '0',
    },
    headerSubtitle: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.6',
        color: 'rgba(255, 255, 255, 0.8)',
        marginTop: '16px',
    },
    cardsContainer: {
        width: '100%',
        display: 'flex',
        gap: '75px',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    pricingCard: {
        width: '296px',
        height: '550px',
        background: 'black',
        borderRadius: '10px',
        border: '2px rgba(255, 255, 255, 0.5) solid',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContent: {
        width: '260px',
        height: '480px',
        padding: '32px 16px',
        borderRadius: '10px',
        background: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        height: '120px'
    },
    cardTitleContainer: {
        // align in a row
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    cardTitle: {
        fontSize: '25px',
        fontWeight: '500',
        margin: '0',
    },
    cardHoverEffect: {
        transition: 'transform 0.3s ease',
    },
    cardHoverEffectHover: {
        transform: 'scale(1.1)',
        border: '2px solid transparent', // Set transparent to make room for the gradient border
    },
    gradientBorder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: '10px',
        padding: '1px', // Adjust as needed for your border width
        background: 'linear-gradient(269deg, rgba(129, 182, 251, 0.70) 8%, #B9ADE3 34%, white 60%, #CCA3BC 84%)',
        zIndex: -1,
    },
    badge: {
        display: 'inline-block',
        padding: '5px 10px',
        background: 'black',
        border: '1px white solid',
        borderRadius: '8px',
        color: 'white',
        fontSize: '11px',
        fontWeight: '300',
        // width: '100px'
    },
    cardSubtitle: {
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '20px',
        color: 'rgba(255, 255, 255, 0.5)',
        margin: '8px 0',
        textDecoration: 'line-through',
    },
    cardSubtitleInvisible: {
        fontSize: '16px',
        fontWeight: '300',
        lineHeight: '20px',
        color: 'rgba(255, 255, 255, 0)',
        margin: '8px 0',
        textDecoration: 'line-through',
    },
    cardPrice: {
        width: '100%',
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'start',
        alignContent: 'center',
        gap: '8px',
    },
    price: {
        fontSize: '32px',
        fontWeight: '500',
    },
    perMonth: {
        fontSize: '16px',
        fontWeight: '300',
        color: 'rgba(255, 255, 255, 0.5)',
    },
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#E7EBFF',
        margin: '16px 0',
    },
    features: {
        listStyle: 'none',
        padding: '0',
        margin: '0 0 auto',
        width: '100%',
        height: '300px',
        textAlign: 'left',
    },
    featureItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        marginBottom: '8px',
    },
    cardButton: {
        width: '100%',
        padding: '10px 0',
        marginTop: '16px',
        border: '1px #3366FF solid',
        borderRadius: '10px',
        background: 'transparent',
        color: '#3D66FB',
        fontSize: '18px',
        fontWeight: '400',
        cursor: 'pointer',
        transition: 'background 0.3s, color 0.3s',
    },
    cardButtonHover: {
        background: '#3366FF',
        color: 'white',
    },
    faqSection: {
        width: '100%',
        maxWidth: '1208px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px',
        color: '#F8F9FA',
        marginBottom: '50px',
    },
    faqTitle: {
        fontSize: '40px',
        fontWeight: '700',
        lineHeight: '1.3',
        textAlign: 'center',
        margin: '0',
    },
    faqSubtitle: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#CFD3D7',
        textAlign: 'center',
    },
    faqList: {
        width: '100%',
    },
    faqItem: {
        background: '#0D1822',
        border: '1px #0D1822 solid',
        borderRadius: '5px',
        padding: '20px',
        margin: '10px',
    },
    faqNumber: {
        fontSize: '16px',
        fontWeight: '700',
        color: '#F8F9FA',
    },
    faqQuestion: {
        fontSize: '16px',
        fontWeight: '700',
        color: '#F8F9FA',
        marginLeft: '16px',
    },
    faqToggle: {
        width: '35.53px',
        height: '24px',
        background: '#3366FF',
        position: 'absolute',
        right: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    toggleIcon: {
        width: '17.76px',
        height: '7.41px',
        background: 'white',
        transform: 'rotate(45deg)',
    }
};

const initialPricingData = [
    {
        title: 'Starter',
        subtitle: '$00.00',
        price: '',
        features: [
            '3 immersive spaces, 3 view points each',
            'Up to 500 images / 5min video / 1GB size limit',
            'Space custimization tools',
        ],
        buttonText: 'Current',
        isRecommended: false,
        isPopular: false,
        isAvailable: true,
    },
    {
        title: 'Essential',
        subtitle: '$34.99',
        price: '',
        features: [
            '10 immersive spaces, 10 view points each',
            'Up to 1000 images / 10min video / 2GB size limit',
            'Advance HD model generation',
            'Prioritized generation',
        ],
        buttonText: 'Get Started',
        isRecommended: true,
        isPopular: false,
        isAvailable: true,
    },
    {
        title: 'Premium',
        subtitle: '$74.99',
        price: '',
        features: [
            '20 immersive spaces',
            'Up to 1500 images / 15min video / 3GB size limit',
            'All Essential features',
            'Object highlights',
            'Intermodel navigation',
            'Guided walk through',
        ],
        buttonText: 'Get Started',
        isRecommended: false,
        isPopular: true,
        isAvailable: false,
    },
    {
        title: 'Enterprise',
        subtitle: '$00.00',
        price: '$00.00',
        features: [
            'Unlimited Immersive Spaces',
            'All Premium features',
            'AI tour guide',
            'User interaction data analytics',
            '1:1 support and feature customization',
        ],
        buttonText: 'Contact Sales',
        isRecommended: false,
        isPopular: false,
        isAvailable: true,
    },
];

const faqData = [
    { question: '1. Can I cancel my subscription?', answer: 'Yes, you can cancel your subscription at any time. The change will take effect at the end of the current billing cycle.' },
    { question: '2. How can I switch between different plans?', answer: 'You can switch your plan at any time through your account settings. If you upgrade to a higher plan, the change will take effect immediately and the charges will be prorated for the remaining time. Downgrades will take effect at the end of the current billing cycle.' },
    { question: '3. What should I do if I encounter technical issues?', answer: 'If you face any technical issues, our support team is ready to assist you. You can submit a ticket through our support page, or join our Discord channel for more immediate assistance.' },
];

const SubscribePlan = ({ user }) => {

    const [pricingData, setPricingData] = useState(initialPricingData);

    const [hoverStates, setHoverStates] = useState({});
    const [buttonHoverStates, setButtonHoverStates] = useState({});
    const [showContactModal, setShowContactModal] = useState(false);

    const [userInfo, setUserInfo] = useState({});
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [expanded, setExpanded] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const getProduct = async (product) => {
            try {
                const productInfo = await userControllerInstance.getSubscriptionPlan(product);
                return productInfo;
            } catch (error) {
                console.error('Error getting subscription plan:', error);
            }
        };

        const fetchPrices = async () => {
            const products = ['Starter', 'Essential', 'Premium', 'Enterprise'];
            let fetchedProducts = [];
            for (let product of products) {
                const productInfo = await getProduct(product);
                if (productInfo) {
                    fetchedProducts.push(productInfo);
                }
            }

            if (fetchedProducts === 0) {
                return;
            } else {
                const sortedProducts = [...fetchedProducts].sort((a, b) => a.price.unit_amount - b.price.unit_amount);
                setPricingData(prevPricingData =>
                    prevPricingData.map((plan, index) => {
                        let price = sortedProducts[index].price.unit_amount !== undefined ? '$' + (sortedProducts[index].price.unit_amount / 100).toFixed(2) : 'N/A';
                        let title = sortedProducts[index].product.name;
                        return {
                            ...plan,
                            price,
                            title
                        };
                    })
                );
            }
        };

        fetchPrices();
    }, []);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                let userDB = await userControllerInstance.getUserById(user.uid);
                if (!userDB.subscription || userDB.subscription.status !== 'active') {
                    userDB.subscription = {
                        plan: 'Starter',
                        subscriptionId: '',
                        status: 'active',
                    };
                }
                setUserInfo(userDB);
            } catch (error) {
                console.error('Failed to get user info:', error);
            }
        };

        getUserInfo();
    }, []);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setSuccess(true);
            setSessionId(query.get('session_id'));
        }

        if (query.get('canceled')) {
            setSuccess(false);
            // setMessage(
            //     "Order canceled -- continue to checkout when you're ready."
            // );
        }
    }, [sessionId]);

    const handleCardMouseEnter = (id) => {
        setHoverStates(prev => ({ ...prev, [id]: true }));
    };

    const handleCardMouseLeave = (id) => {
        setHoverStates(prev => ({ ...prev, [id]: false }));
    };

    const handleButtonMouseEnter = (id) => {
        setButtonHoverStates(prev => ({ ...prev, [id]: true }));
    };

    const handleButtonMouseLeave = (id) => {
        setButtonHoverStates(prev => ({ ...prev, [id]: false }));
    };

    const toggleExpand = (index) => {
        setExpanded(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };

    const handleHelpButton = () => {
        setShowContactModal(true);
    };

    const GradientCheckIcon = () => {
        return (
            <svg width="24" height="24">
                <defs>
                    <linearGradient id="gradientColor" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="8%" stopColor="rgba(129, 182, 251, 0.70)" />
                        <stop offset="34%" stopColor="#B9ADE3" />
                        <stop offset="60%" stopColor="white" />
                        <stop offset="84%" stopColor="#CCA3BC" />
                    </linearGradient>
                </defs>
                <CheckIcon style={{ fill: 'url(#gradientColor)' }} />
            </svg>
        );
    };

    const ErrorMessage = ({ user, message }) => {
        return (
            <div style={styles.page}>
                <Header user={user} />
                <div style={styles.container}>
                    <section>
                        <div className="product">
                            <div className="description">
                                <h3>{message}</h3>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    const handleCheckout = async (userInfo, product) => {
        const info = {
            userId: userInfo._id,
            userEmail: userInfo.email,
            product: product
        };

        try {
            const session = await userControllerInstance.createCheckoutSession(info);

            if (session.url) {
                window.location.href = session.url; // Redirect to the Stripe checkout page
            } else {
                console.error('Error: Unable to create session');
            }
        } catch (error) {
            console.error('Checkout failed:', error);
        }
    };

    const handleCheckoutPortal = async () => {
        try {
            const portalSession = await userControllerInstance.createCheckoutPortalSession(sessionId);

            if (portalSession.url) {
                window.location.href = portalSession.url; // Redirect to the Stripe portal page
            } else {
                console.error('Error: Unable to create session');
            }
        } catch (error) {
            console.error('Portal failed:', error);
        }
    };

    const handleManageBilling = async (subscriptionId) => {
        try {
            const session = await userControllerInstance.createBillingPortalSession(subscriptionId);

            if (session.url) {
                window.location.href = session.url; // Redirect to the Stripe checkout page
            } else {
                console.error('Error: Unable to create session');
            }
        } catch (error) {
            console.error('Checkout failed:', error);
        }
    };

    if (!success && message === '') {
        return (
            <div style={styles.page}>
                <Header user={user} />
                <div style={styles.container}>

                    <div style={styles.headerSection}>
                        <h1 style={styles.headerTitle}>
                            Pick a plan that’s
                            <br />
                            right for you
                        </h1>
                        <img src={subscribeImg} alt="subscribe" />
                        <p style={styles.headerSubtitle}>
                            Pricing plans for everyone.
                            <br />
                            Try our starter plan. No credit card required.
                        </p>
                    </div>

                    {/* card section */}
                    <div style={styles.cardsContainer}>
                        {pricingData.map((plan, index) => (
                            <div
                                key={index}
                                style={{
                                    ...styles.pricingCard,
                                    ...styles.cardHoverEffect,
                                    ...(hoverStates[index] ? styles.cardHoverEffectHover : {})
                                }}
                                onMouseEnter={() => handleCardMouseEnter(index)}
                                onMouseLeave={() => handleCardMouseLeave(index)}
                            >
                                {hoverStates[index] && <div style={styles.gradientBorder}></div>}
                                <div style={styles.cardContent}>
                                    <div style={styles.cardHeader}>
                                        <div style={styles.cardTitleContainer}>
                                            <h2 style={styles.cardTitle}>{plan.title}</h2>
                                            {plan.isRecommended && <span style={styles.badge}>Recommended</span>}
                                            {plan.isPopular && <span style={styles.badge}>Most Popular</span>}
                                        </div>
                                        <p style={plan.title === 'Starter' || plan.title === 'Enterprise' ? styles.cardSubtitleInvisible : styles.cardSubtitle}>{plan.subtitle}</p>
                                        {plan.title === 'Starter' ? (
                                            <div style={styles.cardPrice}>
                                                <span style={styles.price}>Free</span>
                                                <br />
                                                <br />
                                            </div>
                                        ) : plan.title === 'Enterprise' ? (
                                            <div style={styles.cardPrice}>
                                                <span style={styles.price}>Customized</span>
                                                <br />
                                                <br />
                                            </div>
                                        ) : (
                                            <div style={styles.cardPrice}>
                                                <span style={styles.price}>{plan.price}</span>
                                                <span style={styles.perMonth}>per month</span>
                                            </div>
                                        )}
                                    </div>
                                    <hr style={styles.divider} />
                                    <ul style={styles.features}>
                                        {plan.features.map((feature, idx) => (
                                            <li key={idx} style={styles.featureItem}>
                                                <div style={{ width: '25px' }}>
                                                    <GradientCheckIcon />
                                                </div>
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    {plan.isAvailable ? (
                                        <button
                                            style={{
                                                ...styles.cardButton,
                                                ...(buttonHoverStates[index] ? styles.cardButtonHover : {})
                                            }}
                                            onMouseOver={() => handleButtonMouseEnter(index)}
                                            onMouseOut={() => handleButtonMouseLeave(index)}
                                            onClick={() => {
                                                if (index === 0) {
                                                    if (userInfo.subscription && userInfo.subscription.subscriptionId) {
                                                        handleManageBilling(userInfo.subscription.subscriptionId);
                                                    } else {
                                                        if (userInfo.role === 'admin') {
                                                            handleCheckout(userInfo, "Capybara");
                                                        } else {
                                                            return;
                                                        }
                                                    }
                                                } else if (index === 3) {
                                                    handleHelpButton();
                                                    return;
                                                } else {
                                                    if (userInfo.subscription && userInfo.subscription.subscriptionId) {
                                                        handleManageBilling(userInfo.subscription.subscriptionId);
                                                    } else {
                                                        handleCheckout(userInfo, plan.title);
                                                    }
                                                }
                                            }}>

                                            {
                                                index !== 3 &&
                                                    userInfo.subscription &&
                                                    userInfo.subscription.subscriptionId &&
                                                    userInfo.subscription.status === 'active'
                                                    ? (
                                                        userInfo.subscription.plan === plan.title ? "Current" : "Update"
                                                    ) : (
                                                        plan.buttonText
                                                    )}
                                        </button>
                                    ) : (
                                        <button
                                            style={{
                                                ...styles.cardButton,
                                                cursor: 'not-allowed',
                                            }}
                                            disabled
                                        >
                                            Coming Soon
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <br />

                    {/* frequent section */}
                    <div style={styles.faqSection}>
                        <h2 style={styles.faqTitle}>Frequently Asked Questions</h2>
                        {/* <p style={styles.faqSubtitle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> */}

                        <div style={styles.faqList}>
                            {faqData.map((faq, index) => (
                                <div key={index} style={styles.faqItem}>
                                    <div
                                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        onClick={() => toggleExpand(index)}
                                    >
                                        <span>{faq.question}</span>
                                        <span>{expanded[index] ? '-' : '+'}</span>
                                    </div>
                                    {expanded[index] && (
                                        <div style={{ marginTop: '30px' }}>
                                            {faq.answer}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {showContactModal && <ContactUs onClose={() => setShowContactModal(false)} />}
                <Footer />
            </div >
        );
    } else if (success && sessionId !== '') {
        navigate('/');
        // handleCheckoutPortal();
        return null;
    } else {
        return <ErrorMessage user={user} message={message} />;
    }

};



export default SubscribePlan;



