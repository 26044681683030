// Info.js
import React, { useEffect, useState } from 'react';
import SidebarHeader from './SidebarHeader';

const Info = ({ config, handleShowDescription }) => {
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    setShowDescription(true);
  }, []);

  const getInfo = (config) => ({
    name: config.name || 'Property Name',
    username: config.username || 'Author',
    description: config.description.trim() || 'Visit kaleida.space for more information',
  });

  const info = getInfo(config);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        width: '100%', // Ensures full width
        backgroundColor: '#333',
        zIndex: 2000,
        transition: 'transform 0.3s ease-in-out',
        boxSizing: 'border-box',
      }}
    >
      {/* Sidebar Header */}
      <SidebarHeader title="Info" onBack={handleShowDescription} />

      {/* Content Section */}
      <div
        style={{
          padding: '15px',
          overflowY: 'auto',
          height: 'calc(100% - 72px)', // Adjust height to prevent overflow
        }}
      >
        {/* Property Information Section */}
        <h4
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: '20px',
            fontWeight: '600',
            margin: '10px 0',
            wordWrap: 'break-word',
            color: '#fff',
          }}
        >
          {info.name}
        </h4>
        <p
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: '16px',
            fontStyle: 'italic',
            marginBottom: '20px',
            wordWrap: 'break-word',
            color: '#fff',
          }}
        >
          by {info.username}
        </p>
        <p
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: '16px',
            lineHeight: '1.5',
            color: '#fff',
            wordWrap: 'break-word',
          }}
        >
          {info.description}
        </p>
      </div>
    </div>
  );
};

export default Info;
