// Function to calculate the squared distance from a point to a line segment
function distanceSquaredToLineSegment(px, py, x1, y1, x2, y2) {
    const A = px - x1;
    const B = py - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) param = dot / len_sq;

    let xx, yy;

    if (param < 0) {
        xx = x1;
        yy = y1;
    } else if (param > 0 && param < 1) {
        xx = x1 + param * C;
        yy = y1 + param * D;
    } else {
        xx = x2;
        yy = y2;
    }

    const dx = px - xx;
    const dy = py - yy;
    return dx * dx + dy * dy;
}

const isPointTouchingLines = (point, lines, buffer = 0.2) => {
    const [px, py] = point;
    const bufferSquared = buffer * buffer; // Use squared distances to avoid unnecessary square root calculations

    for (let line of lines) {
        const [[x1, y1], [x2, y2]] = line;

        if (distanceSquaredToLineSegment(px, py, x1, y1, x2, y2) <= bufferSquared) {
            return line;
        }
    }

    return null;
}

const slideAlongLine = (startPosition, potentialPosition, lines, buffer = 0.2) => {

    /**
     * if potentialPosition is touching one line, then slide it along the line
     * if more than one line then just return the start position
     */

    let touchedLines = [];
    const bufferSquared = buffer * buffer;

    for (let line of lines) {
        const [[x1, y1], [x2, y2]] = line;
        if (distanceSquaredToLineSegment(potentialPosition[0], potentialPosition[1], x1, y1, x2, y2) <= bufferSquared) {
            touchedLines.push(line);
        }
    }

    const length = touchedLines.length;
    if (length === 0) {
        return potentialPosition;
    } else if (length === 1) {
        // Slide the point along the line
        const line = touchedLines[0];

        // calculate the direction vector of [startPosition, potentialPosition]
        const directionVector = [potentialPosition[0] - startPosition[0], potentialPosition[1] - startPosition[1]];

        // if start position is more close to the line than the potential position, then return the potential position
        const distanceFromStartToLine = distanceSquaredToLineSegment(startPosition[0], startPosition[1], line[0][0], line[0][1], line[1][0], line[1][1]);
        const distanceFromPotentialToLine = distanceSquaredToLineSegment(potentialPosition[0], potentialPosition[1], line[0][0], line[0][1], line[1][0], line[1][1]);
        if (distanceFromStartToLine < distanceFromPotentialToLine) {
            return potentialPosition;
        }

        // calculate the distance between the start position and the potential position
        const distance = Math.sqrt(directionVector[0] ** 2 + directionVector[1] ** 2) * 0.1;

        // calculate the direction vector of the line
        const lineDirection = [line[1][0] - line[0][0], line[1][1] - line[0][1]];

        // if lineDirection is not in the same direction as the directionVector, then reverse the lineDirection
        const dotProduct = lineDirection[0] * directionVector[0] + lineDirection[1] * directionVector[1];
        if (dotProduct < 0) {
            lineDirection[0] = -lineDirection[0];
            lineDirection[1] = -lineDirection[1];
        }

        // where should the point be projected on the line with the distance
        const projectedPoint = [startPosition[0] + lineDirection[0] * distance, startPosition[1] + lineDirection[1] * distance];

        return projectedPoint;
    } else if (length === 2) {

        // just return the start position. need to fix some corner cases
        return startPosition;


        // const line1 = touchedLines[0];
        // const line2 = touchedLines[1];

        // // detect the intersection point of the two lines
        // const [line1Point1, line1Point2] = line1;
        // const [line2Point1, line2Point2] = line2;

        // const distances = {
        //     "Line1Point1AndLine2Point1": Math.sqrt((line1Point1[0] - line2Point1[0]) ** 2 + (line1Point1[1] - line2Point1[1]) ** 2),
        //     "Line1Point1AndLine2Point2": Math.sqrt((line1Point1[0] - line2Point2[0]) ** 2 + (line1Point1[1] - line2Point2[1]) ** 2),
        //     "Line1Point2AndLine2Point1": Math.sqrt((line1Point2[0] - line2Point1[0]) ** 2 + (line1Point2[1] - line2Point1[1]) ** 2),
        //     "Line1Point2AndLine2Point2": Math.sqrt((line1Point2[0] - line2Point2[0]) ** 2 + (line1Point2[1] - line2Point2[1]) ** 2)
        // };

        // const minDistance = Math.min(...Object.values(distances));

        // let intersectionPoint = null;
        // let farPoint = null;
        // if (minDistance === distances["Line1Point1AndLine2Point1"]) {
        //     intersectionPoint = [line1Point1, line2Point1];
        //     farPoint = [line1Point2, line2Point2];
        // } else if (minDistance === distances["Line1Point1AndLine2Point2"]) {
        //     intersectionPoint = [line1Point1, line2Point2];
        //     farPoint = [line1Point2, line2Point1];
        // } else if (minDistance === distances["Line1Point2AndLine2Point1"]) {
        //     intersectionPoint = [line1Point2, line2Point1];
        //     farPoint = [line1Point1, line2Point2];
        // } else if (minDistance === distances["Line1Point2AndLine2Point2"]) {
        //     intersectionPoint = [line1Point2, line2Point2];
        //     farPoint = [line1Point1, line2Point1];
        // }

        // // line direction, line1 from far point to intersection point, line2 from far point to intersection point
        // const line1Direction = [intersectionPoint[0][0] - farPoint[0][0], intersectionPoint[0][1] - farPoint[0][1]];
        // const line2Direction = [farPoint[1][0] - intersectionPoint[1][0], farPoint[1][1] - intersectionPoint[1][1]];

        // // detect if the start position is on the same side of two lines
        // const line1DirectionVector = [startPosition[0] - intersectionPoint[0][0], startPosition[1] - intersectionPoint[0][1]];
        // const line2DirectionVector = [startPosition[0] - intersectionPoint[1][0], startPosition[1] - intersectionPoint[1][1]];

        // const crossProduct1 = line1Direction[0] * line1DirectionVector[1] - line1Direction[1] * line1DirectionVector[0];
        // const crossProduct2 = line2Direction[0] * line2DirectionVector[1] - line2Direction[1] * line2DirectionVector[0];

        // // if the start position is on the different side of the two lines, then return the start position. Otherwise, return the potential position
        // if (crossProduct1 * crossProduct2 < 0) {
        //     console.log("different side");
        //     // if the start position is closer to the intersection point than the potential position, then return the potential position. Otherwise, return the start position
        //     const distanceFromStartToIntersection = Math.sqrt((startPosition[0] - intersectionPoint[0][0]) ** 2 + (startPosition[1] - intersectionPoint[0][1]) ** 2);
        //     const distanceFromPotentialToIntersection = Math.sqrt((potentialPosition[0] - intersectionPoint[0][0]) ** 2 + (potentialPosition[1] - intersectionPoint[0][1]) ** 2);
        //     if (distanceFromStartToIntersection < distanceFromPotentialToIntersection) {
        //         return startPosition;
        //     } else {
        //         return potentialPosition;
        //     }
        // } else {
        //     console.log("same side");
        //     return potentialPosition;
        // }

    } else {
        // if more than two line is touched, then return the start position
        return startPosition;
    }

};

const pointsEqual = (point1, point2) => {
    const areFloatsEqual = (num1, num2, epsilon = 0.000001) => {
        return Math.abs(num1 - num2) < epsilon;
    };

    return areFloatsEqual(point1[0], point2[0]) && areFloatsEqual(point1[1], point2[1]);
};

const calculateCameraPosition = (transformMatrix) => {
    // Step 1: Extract the camera position (last column)
    const cameraPosition = {
        x: transformMatrix[0][3],
        y: transformMatrix[1][3],
        z: -transformMatrix[2][3]
    };

    // Step 2: Extract the LookAt direction (third column)
    const lookAtDirection = {
        x: transformMatrix[0][2],
        y: transformMatrix[1][2],
        z: -transformMatrix[2][2]
    };

    // Step 3: Extract the Up direction (second column)
    const upDirection = {
        x: transformMatrix[0][1],
        y: transformMatrix[1][1],
        z: -transformMatrix[2][1]
    };

    // Step 4: Calculate the LookAt point by adding direction to position
    const lambda = 1.0; // distance to LookAt point
    const lookAtPoint = {
        x: cameraPosition.x + lambda * lookAtDirection.x,
        y: cameraPosition.y + lambda * lookAtDirection.y,
        z: cameraPosition.z + lambda * lookAtDirection.z
    };

    return {
        cameraPosition,
        lookAtPoint,
        upDirection
    };
};

const smoothHistogram = (bins, windowSize = 5) => {
    const smoothedBins = [];
    const halfWindow = Math.floor(windowSize / 2);

    for (let i = 0; i < bins.length; i++) {
        let sum = 0;
        let count = 0;
        for (let j = -halfWindow; j <= halfWindow; j++) {
            const idx = i + j;
            if (idx >= 0 && idx < bins.length) {
                sum += bins[idx];
                count++;
            }
        }
        smoothedBins[i] = sum / count;
    }

    return smoothedBins;
};

const findFirstSignificantPeak = (bins, minProminence = 100) => {
    let localMax = 0;
    let peakBinIndex = null;

    for (let i = 1; i < bins.length - 1; i++) {
        const current = bins[i];
        const prev = bins[i - 1];
        const next = bins[i + 1];

        // Check if the current bin is a local maximum
        if (current > prev && current >= next) {
            let leftMin = Infinity;
            for (let j = Math.max(0, i - 50); j < i; j++) {
                if (bins[j] < leftMin) leftMin = bins[j];
            }

            let rightMin = Infinity;
            for (let j = i + 1; j <= Math.min(bins.length - 1, i + 50); j++) {
                if (bins[j] < rightMin) rightMin = bins[j];
            }

            const prominence = current - Math.max(leftMin, rightMin);

            // Adapt prominence threshold based on the distribution of bin values
            const dynamicThreshold = Math.max(minProminence, (current * 0.1));

            // Only consider this peak if it meets the adaptive prominence threshold
            if (prominence >= dynamicThreshold && current > localMax) {
                localMax = current;
                peakBinIndex = i;
            }
        }
    }

    return peakBinIndex;
};

const findGroundLevel = (points) => {
    const positions = points.position;
    const numPoints = positions.length / 3;

    // Collect all Z values where Z <= 0
    const zValues = [];
    for (let i = 0; i < numPoints; i++) {
        const z = positions[i * 3 + 2];
        if (z <= 0) {
            zValues.push(z);
        }
    }

    if (zValues.length === 0) {
        throw new Error('No points found with Z <= 0');
    }

    // Compute minZ and maxZ
    let minZ = Infinity;
    for (let i = 0; i < zValues.length; i++) {
        const z = zValues[i];
        if (z < minZ) minZ = z;
    }

    // Define bin parameters
    const binSize = 0.01;
    let numBins = Math.ceil((0 - minZ) / binSize);
    if (numBins === 0) numBins = 1;

    // Initialize bins
    const bins = new Array(numBins).fill(0);
    for (const z of zValues) {
        const binIndex = Math.floor((0 - z) / binSize);
        if (binIndex >= 0 && binIndex < bins.length) {
            bins[binIndex]++;
        }
    }

    // generate a plot for first 1000 bins
    const plot = [];
    for (let i = 0; i < 1000; i++) {
        plot.push({ x: i, y: bins[i] });
    }

    // console.log('plot', plot);

    // // Apply smoothing
    // const smoothedBins = smoothHistogram(bins, 30);

    // // Find the first significant peak in the smoothed histogram
    // const groundBinIndex = findFirstSignificantPeak(smoothedBins, 500);

    // if (groundBinIndex === null) {
    //     throw new Error('No significant peak found');
    // }

    // // Compute the Z value corresponding to the bin with the first significant peak
    // const groundZ = 0 - (groundBinIndex + 0.5) * binSize;
    // return {
    //     plot, 
    //     groundZ
    // };

    const groundZ = 0;
    return {plot, groundZ};
};

export {
    isPointTouchingLines,
    slideAlongLine,
    pointsEqual,
    calculateCameraPosition,
    findGroundLevel
};