import { React, useState, useEffect, useMemo, useRef } from 'react';
import { Grid, OrbitControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import * as THREE from 'three';

import { v4 as uuidv4 } from 'uuid';

import { readFileToBuffer, splatToPoints, loadCameraFromJson } from 'Utils/DataLoader';
import { calculateCameraPosition } from 'Utils/Math';

import { CamerasRenderer, PointCloudRenderer, SplatRenderer } from 'components/calibration/ShapeRenderer';

const CameraUpdater = ({ axis, point }) => {
    const { camera, set } = useThree();

    useEffect(() => {
        const setCameraFromAxis = (axis, point) => {
            let position;
            if (axis === 'X') {
                position = [10, 0, 0];
            } else if (axis === 'Y') {
                position = [0, 10, 0];
            } else if (axis === 'Z') {
                position = [0, 0, 10];
            } else {
                position = [10, 10, 10];
            }
            camera.position.set(...position);
        };

        setCameraFromAxis(axis, point);
    }, [axis, point, camera]);

    return null;
};

const MultiSplatEditor = () => {

    // step

    const [step, setStep] = useState(0);

    // toggle

    const [showPointCloud, setShowPointCloud] = useState(true);

    // Error

    const [error, setError] = useState(null);

    // Load splat file

    const [splatData, setSplatData] = useState([]);
    const [selectedSplat, setSelectedSplat] = useState(null);

    const handleSplatFileChange = async (e) => {
        const file = e.target.files[0];

        if (!file) {
            setError('No file selected');
            return;
        } else {
            console.log('File selected:', file);
            const splat = URL.createObjectURL(file)

            const buffer = await readFileToBuffer(file);
            const pointCloud = splatToPoints(buffer);

            setSplatData([...splatData, {
                name: file.name,
                splat: splat,
                pointCloud: pointCloud,
                calibration: {
                    scale: 1.0,
                    rotation: [0, 0, 0],
                    translation: [0, 0, 0]
                },
                range: { x: [-20, 20], y: [-20, 20], z: [-20, 20] },
                hide: false
            }]);

            setError(null);
        }
    };

    // initialize calibration

    const scaleStep = 0.1;
    const rotateStep = 1;
    const translateStep = 0.1;
    const rangeStep = 0.1;

    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });
    const [translation, setTranslation] = useState({ x: 0, y: 0, z: 0 });

    const [range, setRange] = useState({ x: [-20, 20], y: [-20, 20], z: [-20, 20] });

    const handleSelectSplat = (index) => {
        const splatRotation = splatData[index].calibration.rotation.map((angle) => angle * 180 / Math.PI);
        const splatTranslation = splatData[index].calibration.translation;

        setSelectedSplat(index);
        setScale(splatData[index].calibration.scale);
        setRotation({ x: splatRotation[0], y: splatRotation[1], z: splatRotation[2] });
        setTranslation({ x: splatTranslation[0], y: splatTranslation[1], z: splatTranslation[2] });
        setRange({ ...splatData[index].range });
        setError(null);
    };

    const handleDeleteSplat = (selectedIndex) => {
        const newSplatData = splatData.filter((splat, index) => index !== selectedIndex);
        setSplatData(newSplatData);
        setSelectedSplat(null);
        setScale(1);
        setRotation({ x: 0, y: 0, z: 0 });
        setTranslation({ x: 0, y: 0, z: 0 });
        setRange({ x: [-20, 20], y: [-20, 20], z: [-20, 20] });
    };

    const [loading, setLoading] = useState(true);

    const handleSetScale = (value) => {
        setScale(value);
        const newSplatData = splatData.map((splat, index) => {
            if (index === selectedSplat) {
                return {
                    ...splat,
                    calibration: {
                        ...splat.calibration,
                        scale: value
                    }
                };
            } else {
                return splat;
            }
        });
        setSplatData(newSplatData);
    };

    const handleSetRotation = (value) => {
        setRotation(value);
        const newSplatData = splatData.map((splat, index) => {
            if (index === selectedSplat) {
                return {
                    ...splat,
                    calibration: {
                        ...splat.calibration,
                        rotation: [value.x / 180 * Math.PI, value.y / 180 * Math.PI, value.z / 180 * Math.PI]
                    }
                };
            } else {
                return splat;
            }
        });
        setSplatData(newSplatData);
    };

    const handleSetTranslation = (value) => {
        setTranslation(value);
        const newSplatData = splatData.map((splat, index) => {
            if (index === selectedSplat) {
                return {
                    ...splat,
                    calibration: {
                        ...splat.calibration,
                        translation: [value.x, value.y, value.z]
                    }
                };
            } else {
                return splat;
            }
        });
        setSplatData(newSplatData);
    };

    const handleSetRange = (value) => {
        setRange(value);
        const newSplatData = splatData.map((splat, index) => {
            if (index === selectedSplat) {
                return {
                    ...splat,
                    range: value
                };
            } else {
                return splat;
            }
        });
        setSplatData(newSplatData);
    };

    const handleHide = (index) => {
        const newSplatData = splatData.map((splat, i) => {
            if (i === index) {
                return {
                    ...splat,
                    hide: !splat.hide
                };
            } else {
                return splat;
            }
        });
        setSplatData(newSplatData);
    };

    return (
        <div
            style={{
                position: 'relative',
                height: '100vh',
                width: '100vw',
            }}
        >
            <div style={{
                position: 'absolute',
                top: '10%',
                right: '20px',
                width: '300px',
                height: '75vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '20px',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                zIndex: 1000
            }}>
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        padding: '0 20px 0 0',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                    }}
                >

                    <div>
                        <h2 style={{ marginBottom: '20px' }}>Editor</h2>

                        <button
                            onClick={() => setStep(0)}
                        >Step 0: Choose File</button>

                        <br />

                        <button
                            onClick={() => {
                                if (selectedSplat === null) {
                                    setError('No splat selected');
                                    return;
                                } else {
                                    setStep(1);
                                }
                            }}
                        >Step 1: Calibration</button>
                    </div>

                    <div style={{
                        height: '5px',
                        width: '100%',
                        backgroundColor: 'darkgray',
                        margin: '20px 0'
                    }}></div>

                    {step === 0 && <div>
                        <div style={{ marginBottom: '20px' }}>
                            <h4 style={{ marginBottom: '10px' }}>Upload Splat Model</h4>
                            <input
                                type="file"
                                onChange={handleSplatFileChange}
                                accept=".splat"
                                style={{ padding: '8px', width: '100%' }}
                            />
                        </div>

                        {error && <p style={{ color: 'red' }}>{error}</p>}

                        {(selectedSplat !== null) ? <p>Selected Splat: {splatData[selectedSplat].name}</p> : <p>No splat selected</p>}

                        {splatData.length > 0 && <div>
                            <h4 style={{ marginBottom: '10px' }}>Splat Models</h4>
                            <ul>
                                {splatData.map((splat, index) => {
                                    return (
                                        <div
                                            key={uuidv4()}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <li key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectSplat(index)}>{splat.name}</li>

                                            <div>
                                                <button
                                                    onClick={() => {
                                                        handleHide(index);
                                                    }}
                                                >
                                                    {splat.hide ? "show" : "hide"}
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        handleDeleteSplat(index);
                                                    }}
                                                >delete</button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                        </div>}
                    </div>}

                    {step === 1 &&
                        <div>
                            <button
                                onClick={() => setShowPointCloud(!showPointCloud)}
                            >{showPointCloud ? "point cloud" : "splat"}</button>

                            <br />
                            <br />

                            {(selectedSplat !== null) ? <p>Selected Splat: {splatData[selectedSplat].name}</p> : <p>No splat selected</p>}

                            <div style={{ marginTop: '20px' }}>
                                {/* Scale Control */}
                                <h4>Scale</h4>
                                <span>{scale}</span>
                                <input
                                    type="range"
                                    min="0.1"
                                    max="10"
                                    step={scaleStep}
                                    value={scale}
                                    onChange={(e) => handleSetScale(e.target.value)}
                                    style={{ width: '100%' }}
                                />

                                {/* Rotation Controls */}
                                <h4 style={{ marginTop: '20px' }}>Rotation (Degrees)</h4>
                                <label>X: </label>
                                <span>{rotation.x}°</span>
                                <input
                                    type="range"
                                    min="-180"
                                    max="180"
                                    step={rotateStep}
                                    value={rotation.x}
                                    onChange={(e) => handleSetRotation({ ...rotation, x: e.target.value })}
                                    style={{ width: '100%' }}
                                />

                                <label>Y: </label>
                                <span>{rotation.y}°</span>
                                <input
                                    type="range"
                                    min="-180"
                                    max="180"
                                    step={rotateStep}
                                    value={rotation.y}
                                    onChange={(e) => handleSetRotation({ ...rotation, y: e.target.value })}
                                    style={{ width: '100%' }}
                                />

                                <label>Z: </label>
                                <span>{rotation.z}°</span>
                                <input
                                    type="range"
                                    min="-180"
                                    max="180"
                                    step={rotateStep}
                                    value={rotation.z}
                                    onChange={(e) => handleSetRotation({ ...rotation, z: e.target.value })}
                                    style={{ width: '100%' }}
                                />

                                {/* Translation Controls */}
                                <h4 style={{ marginTop: '20px' }}>Translation</h4>
                                <label>X: </label>
                                <span>{translation.x}</span>
                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={translateStep}
                                    value={translation.x}
                                    onChange={(e) => handleSetTranslation({ ...translation, x: e.target.value })}
                                    style={{ width: '100%' }}
                                />

                                <label>Y: </label>
                                <span>{translation.y}</span>
                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={translateStep}
                                    value={translation.y}
                                    onChange={(e) => handleSetTranslation({ ...translation, y: e.target.value })}
                                    style={{ width: '100%' }}
                                />

                                <label>Z: </label>
                                <span>{translation.z}</span>
                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={translateStep}
                                    value={translation.z}
                                    onChange={(e) => handleSetTranslation({ ...translation, z: e.target.value })}
                                    style={{ width: '100%' }}
                                />

                                <h4 style={{ marginTop: '20px' }}>Range</h4>
                                <label>X: </label>
                                <span>{range.x[0]} - {range.x[1]}</span>
                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={rangeStep}
                                    value={range.x[0]}
                                    onChange={(e) => handleSetRange({ ...range, x: [e.target.value, range.x[1]] })}
                                    style={{ width: '100%' }}
                                />

                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={rangeStep}
                                    value={range.x[1]}
                                    onChange={(e) => handleSetRange({ ...range, x: [range.x[0], e.target.value] })}
                                    style={{ width: '100%' }}
                                />

                                <label>Y: </label>
                                <span>{range.y[0]} - {range.y[1]}</span>
                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={rangeStep}
                                    value={range.y[0]}
                                    onChange={(e) => handleSetRange({ ...range, y: [e.target.value, range.y[1]] })}
                                    style={{ width: '100%' }}
                                />

                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={rangeStep}
                                    value={range.y[1]}
                                    onChange={(e) => handleSetRange({ ...range, y: [range.y[0], e.target.value] })}
                                    style={{ width: '100%' }}
                                />

                                <label>Z: </label>
                                <span>{range.z[0]} - {range.z[1]}</span>
                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={rangeStep}
                                    value={range.z[0]}
                                    onChange={(e) => handleSetRange({ ...range, z: [e.target.value, range.z[1]] })}
                                    style={{ width: '100%' }}
                                />

                                <input
                                    type="range"
                                    min="-20"
                                    max="20"
                                    step={rangeStep}
                                    value={range.z[1]}
                                    onChange={(e) => handleSetRange({ ...range, z: [range.z[0], e.target.value] })}
                                    style={{ width: '100%' }}
                                />


                                <br />
                                <br />

                                {/* <button
                                    onClick={() => {
                                        setScale(1);
                                        setRotation({ x: 0, y: 0, z: 0 });
                                        setTranslation({ x: 0, y: 0, z: 0 });
                                        setRange({ x: [-20, 20], y: [-20, 20], z: [-20, 20] });
                                    }}
                                >Reset</button> */}
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Canvas
                style={{ background: 'white' }}
                gl={{ localClippingEnabled: true }}
                camera={{ fov: 50 }}
            >
                {splatData.length > 0 && <>
                    {showPointCloud && <>
                        {splatData.map((data) => {
                            if (data.hide) {
                                return null;
                            } else {
                                return <PointCloudRenderer key={uuidv4()} points={data.pointCloud} calibration={data.calibration} range={data.range} setLoading={setLoading} />
                            }
                        })}
                    </>}
                    {!showPointCloud && <>
                        {splatData.map((data) => {
                            if (data.hide) {
                                return null;
                            } else {
                                return <SplatRenderer key={uuidv4()} url={data.splat} calibration={data.calibration} range={data.range} />
                            }
                        })}
                    </>}
                </>}

                <axesHelper args={[5]} />

                <CameraUpdater axis={'N'} />

                <OrbitControls
                    enableRotate={true}
                    minPolarAngle={0}
                    maxPolarAngle={Math.PI / 2}
                />

                {/* <Grid
                    position={[0, 0, 0]}
                    cellSize={1}
                    sectionSize={4}
                    args={[100, 100]}
                    fadeDistance={100}
                    sectionColor="#000000"
                    cellColor="#000000"
                /> */}

            </Canvas>
        </div>
    );

};

export default MultiSplatEditor;