import SessionModel from "models/SessionModel";

class SessionController {
    constructor(sessionModel) {
        this.sessionModel = sessionModel;
    }

    async createSession(data) {
        try {
            const response = await this.sessionModel.createSession(data);
            return response;
        } catch (error) {
            console.error("Failed to create session:", error);
            throw error;
        }
    }

}

const SessionControllerInstance = new SessionController(SessionModel);
export default SessionControllerInstance;