import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import pointcloud from 'asset/pointcloud(2).png';
import Header from '../Header';
import avp from 'asset/apple-vision-pro_2x-gray.png';
import LoginPage from '../authentication/LoginPage';
import UploadModal from '../dashboard/UploadModal';
import { getAuth } from 'firebase/auth';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { PointCloudRendererSlow } from '../calibration/ShapeRenderer';
import { readFileToBuffer, splatToPoints, loadCameraFromJson } from '../../Utils/DataLoader';
import { reducePoints } from '../../Utils/SplatMath';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const Modal = ({ message, onClose }) => {
  const styles = {
    modal: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      zIndex: 1000,
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      color: '#aaa',
      fontSize: '24px',
    },
    h2: {
      fontFamily: '"Poppins", sans-serif',
      fontSize: '18px',
      fontWeight: '400',
      color: '#666666',
      textAlign: 'center',
      marginBottom: '24px',
    },
  };

  return (
    <>
      <div style={styles.overlay} onClick={onClose}></div>
      <div style={styles.modal}>
        <button onClick={onClose} style={styles.closeButton}>×</button>
        <h2 style={styles.h2}>{message}</h2>
      </div>
    </>
  );
};

const HeroSection = ({ user }) => {
  const { width } = useWindowSize();
  const [pointCloudData, setPointCloudData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const isMobile = width < 768;

  const navigate = useNavigate();

  // Initialize AOS animations
  useEffect(() => {
    AOS.init({ duration: 1000, });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch point cloud data
        const buffer = await readFileToBuffer("https://s3.amazonaws.com/kaleida.space/assets/hero.splat");
        const originPoints = splatToPoints(buffer);
        // const reducedPoints = reducePoints(originPoints, 100);
        setPointCloudData(originPoints);
      } catch (err) {
        setError('Failed to load point cloud or camera data');
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const handleCreateTour = () => {
    const auth = getAuth();
    if (auth.currentUser) {
      navigate('/dashboard', { state: { showModal: true } });
    } else {
      setShowPopup(true);
    }
  };

  const styles = {
    section: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center', // Center content vertically in the section
      backgroundColor: '#040507',
      color: '#fff',
      minHeight: '100vh', // Full screen height
      boxSizing: 'border-box',
      overflow: 'hidden', // Prevent overflow
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Center content inside contentWrapper
      alignItems: isMobile ? 'center' : 'flex-start',
      textAlign: isMobile ? 'center' : 'left',
      maxWidth: '100%',
      zIndex: 5,
      paddingTop: isMobile ? '300px' : "400px", // Add top padding to avoid overlap with the header
      padding: isMobile ? '0 20px' : '0',
    },
    h1: {
      fontSize: isMobile ? '40px' : '72px',
      fontWeight: '600',
      marginBottom: isMobile ? '30px' : '50px',
      fontFamily: '"Poppins", sans-serif',
      background: 'linear-gradient(90deg, #81B6FB 0%, #A0BFFB 15%, #B9ADE3 30%, #FFFFFF 50%, #CCA3BC 70%, #A0BFFB 85%, #81B6FB 100%)',
      backgroundSize: '200% 100%',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      animation: 'gradientAnimation 3s ease infinite',
    },
    p: {
      fontSize: isMobile ? '16px' : '18px',
      marginBottom: '20px',
      fontFamily: '"Poppins", sans-serif',
      lineHeight: 2,
      opacity: 0.8,
      padding: isMobile ? '0 40px' : '0',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile, side by side on larger screens
      gap: '15px',
      justifyContent: isMobile ? 'center' : 'flex-start',
      alignItems: isMobile ? 'center' : 'flex-start',
    },
    button: {
      background: '#3366FF',
      color: '#fff',
      border: 'none',
      padding: '13px 20px',
      borderRadius: '10px',
      cursor: 'pointer',
      fontFamily: '"Poppins", sans-serif',
      textDecoration: 'none',
      fontSize: isMobile ? '14px' : '16px',
      transition: 'transform 0.3s ease',
      width: isMobile ? '100%' : 'auto', // Full width on mobile
      marginBottom: isMobile ? '15px' : '0', // Add margin between buttons on mobile
    },
    buttonHover: {
      transform: 'scale(1.05)',
    },
    secondaryButton: {
      backgroundColor: 'transparent',
      color: '#fff',
      border: '2px solid #CBD0DC',
      padding: '11px 20px',
      borderRadius: '10px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      fontFamily: '"Poppins", sans-serif',
      fontSize: isMobile ? '14px' : '16px',
      textDecoration: 'none',
      width: isMobile ? '100%' : 'auto', // Full width on mobile
    },
    imgDiv: {
      width: isMobile ? '100%' : '50%',
      marginTop: isMobile ? '20px' : '0',
      animation: 'fade-in 1s ease-in-out',
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: '10px',
    },
    icon: {
      marginLeft: '8px',
      height: '20px',
      verticalAlign: 'middle',
    },
    canvasContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 0,
      backgroundColor: 'black',
      width: '100%',
      height: '100%',
      opacity: 0.5,
    },
    mask: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      // backgroundColor: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black
      zIndex: 1,
    }
  };

  return (
    <>
      {showPopup && <LoginPage onClose={() => setShowPopup(false)} />}
      {showModal && (
        <Modal
          message={modalMessage}
          onClose={() => setShowModal(false)}
        />
      )}
      {showUploadModal && <UploadModal user={user} onClose={() => setShowUploadModal(false)} />}

      {/* Inline styles for keyframes */}
      <style>{`
        @keyframes gradientAnimation {
          0% {
            background-position: 0% center;
          }
          100% {
            background-position: -200% center;
          }
        }
      `}</style>
      <section style={styles.section}>
        <Header user={user} />
        <div style={styles.contentWrapper}>
          <h1 style={styles.h1} data-aos="fade-up">Beyond Reality, <br /> Everywhere</h1>
          <p style={styles.p}>Transforming property showcasing with 3D immersive experience, building next-gen customer exploration.
            <br />
            Now available on Apple Vision Pro.</p>
          <div style={styles.buttonContainer}>
            <button
              onClick={handleCreateTour}
              style={styles.button}
              onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
            >
              Create Your Immersive Experience
            </button>
            <a
              href="https://testflight.apple.com/join/ayAkVBck"
              target="_blank"
              rel="noopener noreferrer"
              style={{ ...styles.secondaryButton }}
            >
              Join the Beta on Apple Vision Pro
              <img src={avp} alt="apple-vision-pro" style={styles.icon} />
            </a>
          </div>
        </div>
        <div style={styles.canvasContainer}>
          <Canvas camera={{ fov: 50, position: [0, 2, 2] }} style={{ backgroundColor: 'black' }} gl={{ localClippingEnabled: true }}>
            {pointCloudData && (
              <PointCloudRendererSlow points={pointCloudData} calibration={{ scale: 1, rotation: [0, 0, 0], translation: [0, 0, 0] }} range={{ x: [-20, 20], y: [-20, 20], z: [-20, 20] }} setLoading={setLoading} />
            )}
            <OrbitControls
              autoRotate={true}              // Enable auto-rotation
              autoRotateSpeed={0.5}          // Adjust rotation speed
              enableDamping={true}           // Smooth the movement
              minPolarAngle={Math.PI / 2}
              maxPolarAngle={Math.PI / 2}    // Limit polar angle for rotation
              enableZoom={false}
              enablePan={false}
              enableRotate={false}
            />
          </Canvas>
          <div style={styles.mask}></div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
