import axios from 'axios';

const splatToPoints = (data) => {
    const recordSize = 12 + 12 + 4 + 4; // 32 bytes per record
    if (data.byteLength % recordSize !== 0) {
        throw new Error('Invalid file length: not a multiple of record size');
    }

    const length = data.byteLength / recordSize;

    const points = {
        position: new Float32Array(length * 3),
        scale: new Float32Array(length * 3),
        color: new Float32Array(length * 4),
        rotation: new Float32Array(length * 4)
    };

    const view = new DataView(data);

    let index = 0;
    while (index < length) {
        let offset = index * recordSize;
        
        try {
            // Read position (3 floats)
            const x = view.getFloat32(offset + 0, true);
            const y = -view.getFloat32(offset + 4, true);
            const z = -view.getFloat32(offset + 8, true);
            offset += 12;

            // Validate position
            if (isNaN(x) || isNaN(y) || isNaN(z)) {
                throw new Error('Invalid position data');
            }

            // Read scale (3 floats)
            const scaleX = view.getFloat32(offset + 0, true);
            const scaleY = view.getFloat32(offset + 4, true);
            const scaleZ = view.getFloat32(offset + 8, true);
            offset += 12;

            // Validate scale
            if (isNaN(scaleX) || isNaN(scaleY) || isNaN(scaleZ)) {
                throw new Error('Invalid scale data');
            }

            // Read color (4 bytes)
            const r = view.getUint8(offset + 0) / 255;
            const g = view.getUint8(offset + 1) / 255;
            const b = view.getUint8(offset + 2) / 255;
            const a = view.getUint8(offset + 3) / 255;
            offset += 4;

            // Validate color values
            if (r < 0 || r > 1 || g < 0 || g > 1 || b < 0 || b > 1 || a < 0 || a > 1) {
                throw new Error('Invalid color data');
            }

            // Read rotation (4 bytes)
            const rotX = -(view.getUint8(offset + 1) - 128) / 128;
            const rotY = (view.getUint8(offset + 2) - 128) / 128;
            const rotZ = (view.getUint8(offset + 3) - 128) / 128;
            const rotW = -(view.getUint8(offset + 0) - 128) / 128;
            offset += 4;

            // Validate rotation values
            if (rotX < -1 || rotX > 1 || rotY < -1 || rotY > 1 || rotZ < -1 || rotZ > 1 || rotW < -1 || rotW > 1) {
                throw new Error('Invalid rotation data');
            }

            points.position[index * 3 + 0] = x;
            points.position[index * 3 + 1] = y;
            points.position[index * 3 + 2] = z;

            points.scale[index * 3 + 0] = scaleX;
            points.scale[index * 3 + 1] = scaleY;
            points.scale[index * 3 + 2] = scaleZ;

            points.color[index * 4 + 0] = r;
            points.color[index * 4 + 1] = g;
            points.color[index * 4 + 2] = b;
            points.color[index * 4 + 3] = a;

            points.rotation[index * 4 + 0] = rotX;
            points.rotation[index * 4 + 1] = rotY;
            points.rotation[index * 4 + 2] = rotZ;
            points.rotation[index * 4 + 3] = rotW;

        } catch (error) {
            console.error('Error parsing record at offset', offset, ':', error.message);
            throw error;
        }

        index++;
    }

    return points;
};

const loadCameraFromJson = async (input) => {
    const buffer = await readFileToBuffer(input);
    const jsonData = JSON.parse(new TextDecoder().decode(buffer));

    const camera = {
        width: jsonData.w,
        height: jsonData.h,
        focalLengthX: jsonData.fl_x,
        focalLengthY: jsonData.fl_y,
        principalPointX: jsonData.cx,
        principalPointY: jsonData.cy,
        distortionCoefficients: {
            k1: jsonData.k1,
            k2: jsonData.k2,
            p1: jsonData.p1,
            p2: jsonData.p2
        },
        cameraModel: jsonData.camera_model,
        frames: jsonData.frames.map(frame => ({
            filePath: frame.file_path,
            transformMatrix: frame.transform_matrix,
            colmapImageId: frame.colmap_im_id
        }))
    };

    return camera;
};

const readFileToBuffer = async (input) => {
    try {
        if (typeof input === "string") {
            if (input.startsWith('http://') || input.startsWith('https://')) {
                // Fetch file from URL
                const response = await axios.get(input, { responseType: 'arraybuffer' });
                return response.data;
            } else {
                throw new Error('Invalid input: not a URL');
            }
        } else if (input instanceof File) {
            // Read file from input element
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
                reader.readAsArrayBuffer(input);
            });
        } else {
            throw new Error('Invalid input: not a URL or File');
        }
    } catch (error) {
        console.error('Error reading file:', error.message);
        throw error;
    }
};

export {
    splatToPoints,
    loadCameraFromJson,
    readFileToBuffer,
};