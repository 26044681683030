import React, { useEffect, useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import RangeController from "./RangeController";

const GeneralSettingController = ({
    info, setInfo,
    interaction, setInteraction,
    range, handleSetRange
}) => {

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%', // Adjust as needed for parent height
        },
        content: {
            flexGrow: 1,
        },
        editIcon: {
            fontSize: '20px',
            cursor: 'pointer',
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            padding: '5px',
            borderRadius: '30%',
        },
        editButton: {
            padding: '5px 20px',
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: '"Poppins", sans-serif',
            cursor: 'pointer',
            textAlign: 'center',
            borderRadius: '8px',
            backgroundColor: 'transparent',
            color: '#fff',
            border: '1px solid #fff',
            width: '100%',
        },
        nameContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#fff',
            margin: '15px 0',
        },
        description: {
            color: '#FFFFFFB2',
            fontSize: '16px',
            fontFamily: '"Poppins", sans-serif',
            margin: '10px 0',
            wordBreak: 'break-word',
        },
        helpIcon: {
            backgroundColor: 'rgba(255, 255, 255, 0.25)',
            borderRadius: '50%',
            fontSize: '20px',
            cursor: 'pointer',
        }
    };

    // name

    const [nameInputValue, setNameInputValue] = useState(info.name.trim());  // Initialize with info.name
    const [editingName, setEditingName] = useState(false);

    const handleSetEditingName = () => {
        setEditingName(true);
    };

    const handleNameInputChange = (e) => {
        setNameInputValue(e.target.value);  // Update inputValue as user types
    };

    const handleNameInputBlur = () => {
        if (nameInputValue.trim() !== '') {  // Check for non-empty input
            setInfo({
                ...info,
                name: nameInputValue,
            });
        }
        setEditingName(false);
    };

    const handleNameInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleNameInputBlur();
        }
    }

    // description

    const [descriptionInputValue, setDescriptionInputValue] = useState(info.description.trim());  // Initialize with info.description
    const [editingDescription, setEditingDescription] = useState(false);

    const handleSetEditingDescription = () => {
        setEditingDescription(true);
    }

    const handleDescriptionInputChange = (e) => {
        setDescriptionInputValue(e.target.value);  // Update inputValue as user types
    }

    const handleDescriptionInputBlur = () => {
        if (descriptionInputValue.trim() !== '') {  // Check for non-empty input
            setInfo({
                ...info,
                description: descriptionInputValue,
            });
        }
        setEditingDescription(false);
    }

    // interaction description
    const [showInteractionDescription, setShowInteractionDescription] = useState(false);
    const handleShowInteractionDescription = () => {
        setShowInteractionDescription(!showInteractionDescription);
    };

    // useEffect to update input values when info changes

    useEffect(() => {
        setNameInputValue(info.name.trim());
        setDescriptionInputValue(info.description.trim());
    }, [info.name, info.description]);

    const handleInteractionChange = (event, newInteraction) => {
        // console.log(newInteraction);
        if (newInteraction !== null) setInteraction(newInteraction);
    };

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                {!editingName && <div style={styles.nameContainer} >
                    <h2
                        style={{
                            fontFamily: '"Poppins", sans-serif',
                            userSelect: 'none',
                            transition: 'color 0.3s',
                            width: '85%',
                            margin: 0,
                            wordBreak: 'break-word',
                        }}
                    >
                        {info.name}
                    </h2>
                    <EditIcon
                        style={styles.editIcon}
                        onClick={handleSetEditingName}
                    />
                </div>
                }

                {editingName && <div style={styles.nameContainer} >
                    <input
                        type="text"
                        value={nameInputValue}  // Bind to inputValue
                        onChange={handleNameInputChange}
                        onBlur={handleNameInputBlur}
                        onKeyDown={handleNameInputKeyDown}
                        maxLength={40}
                        style={{
                            fontFamily: '"Poppins", sans-serif',
                            fontSize: '16px',
                            width: '70%',
                            margin: 0,
                        }}
                        autoFocus  // Automatically focus when editing
                    />
                    <SaveIcon
                        style={styles.editIcon}
                        onClick={handleNameInputBlur}
                    />
                </div>}

                <br />

                {!editingDescription && <div>
                    <p style={styles.description}>
                        {info.description ? info.description.split('\n').map((line, index) => (
                            <span key={index}>{line}<br /></span>
                        )) : 'No description provided.'}
                    </p>

                    <br />
                    <button
                        style={styles.editButton}
                        onClick={handleSetEditingDescription}
                    >
                        {info.description ? 'Edit Description' : 'Add Description'}
                    </button>
                </div>}

                {editingDescription && <div>
                    <textarea
                        value={descriptionInputValue}  // Bind to inputValue
                        onChange={handleDescriptionInputChange}
                        onBlur={handleDescriptionInputBlur}
                        style={{
                            fontFamily: '"Poppins", sans-serif',
                            fontSize: '16px',
                            width: '100%',
                            height: '150px',
                            marginBottom: '10px',
                        }}
                        autoFocus  // Automatically focus when editing
                        maxLength={450}
                    />
                    <button
                        style={styles.editButton}
                        onClick={handleDescriptionInputBlur}
                    >
                        Save Description
                    </button>
                </div>}
            </div>

            <br />

            <label
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#fff',
                    margin: '15px 0',
                }}
            >
                <h2
                    style={{
                        fontFamily: '"Poppins", sans-serif',
                        userSelect: 'none',
                        transition: 'color 0.3s',
                        margin: 0,
                    }}
                >
                    Interaction Mode
                </h2>
                <HelpOutlineIcon
                    style={styles.helpIcon}
                    onClick={handleShowInteractionDescription}
                />
            </label>

            {showInteractionDescription && <p
                style={{
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: '16px',
                    color: '#FFFFFFB2',
                    margin: '10px 0',
                }}
            >
                First-person View allows you to move around the space as if you were inside it. Orbital View allows you to rotate the space around a fixed axis.
            </p>}

            <ToggleButtonGroup
                value={interaction}
                exclusive
                onChange={handleInteractionChange}
                aria-label="interaction mode selection"
                style={{
                    height: '40px',
                }}
            >
                <ToggleButton
                    value="FirstPerson"
                    aria-label="FirstPerson"
                    style={{
                        width: '120px',
                        height: '20px',
                        backgroundColor: interaction === 'FirstPerson' ? '#3366FF' : 'transparent',
                        color: interaction === 'FirstPerson' ? '#fff' : '#CBD0DC',
                        border: '1px solid #CBD0DC',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                    }}
                >
                    First-person
                </ToggleButton>
                <ToggleButton
                    value="Orbital"
                    aria-label="Orbital"
                    style={{
                        width: '120px',
                        height: '20px',
                        backgroundColor: interaction === 'Orbital' ? '#3366FF' : 'transparent',
                        color: interaction === 'Orbital' ? '#fff' : '#CBD0DC',
                        border: '1px solid #CBD0DC',
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                    }}
                >
                    Orbital
                </ToggleButton>
            </ToggleButtonGroup>

            <br />
            {interaction === 'Orbital' && (
            <RangeController
                range={range}
                handleSetRange={handleSetRange}
            />
            )}

        </div>
    );
};

export default GeneralSettingController;
