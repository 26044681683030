// import React, { useState, useEffect, useRef } from 'react';
// import _741gif from 'asset/741clip.gif';
// import extgif from 'asset/extclip.gif';
// import loungegif from 'asset/loungeclip.gif';
// import avpclick from 'asset/avpclick.gif';

// const useWindowSize = () => {
//   const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

//   useEffect(() => {
//     const handleResize = () => {
//       setSize([window.innerWidth, window.innerHeight]);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   return size;
// };

// // Lazy loading using IntersectionObserver
// const LazyLoadImage = ({ src, alt, style }) => {
//   const imgRef = useRef();
//   const [isVisible, setIsVisible] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         if (entries[0].isIntersecting) {
//           setIsVisible(true);
//           observer.disconnect();
//         }
//       },
//       { threshold: 0.1 }
//     );

//     observer.observe(imgRef.current);

//     return () => observer.disconnect();
//   }, []);

//   return (
//     <img
//       ref={imgRef}
//       src={isVisible ? src : ''}
//       alt={alt}
//       style={{ ...style, opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }}
//     />
//   );
// };

// const styles = (isMobile) => ({
//   howItWorksSection: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '5%',
//     backgroundColor: '#040507',
//     color: '#fff',
//     width: '100%',
//     boxSizing: 'border-box',
//     overflow: 'hidden', // Important for parallax or overflow effects
//   },
//   title: {
//     textAlign: 'center',
//     width: '100%',
//     marginBottom: '5%',
//     fontSize: '2em',
//     fontFamily: '"Poppins", sans-serif',
//     fontWeight: '400',
//     letterSpacing: '0.05em',
//     background: 'linear-gradient(90deg, #81B6FB, #B9ADE3, #FFFFFF, #CCA3BC, #81B6FB)',
//     WebkitBackgroundClip: 'text',
//     WebkitTextFillColor: 'transparent',
//     animation: 'gradientMove 6s ease infinite',
//   },
//   cardsContainer: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     width: '100%',
//     justifyContent: 'space-between',
//     gap: '2%',
//   },
//   card: {
//     flex: isMobile ? '1 1 100%' : '1 1 48%',
//     textAlign: 'center',
//     justifyContent: 'center',
//     gap: '10px',
//     boxSizing: 'border-box',
//     marginBottom: '2%',
//     overflow: 'hidden',
//     position: 'relative',
//     borderRadius: '10px',
//     transition: 'transform 0.5s ease-in-out',
//   },
//   cardHover: {
//     transform: 'scale(1.05)', // Add hover effect
//   },
//   icon: {
//     width: '100%',
//     height: 'auto',
//     marginBottom: '10%',
//     borderRadius: '10px',
//   },
//   cardTitle: {
//     fontFamily: '"Poppins", sans-serif',
//     fontSize: '1.5em',
//     fontWeight: '600',
//     marginBottom: '1em',
//   },
//   cardDescription: {
//     fontFamily: '"Poppins", sans-serif',
//     fontWeight: '400',
//     fontSize: '1.5em',
//     opacity: 0.5,
//   },
// });

// const WorkCard = ({ icon }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const [width] = useWindowSize();
//   const isMobile = width < 768;
//   const appliedStyles = styles(isMobile);

//   return (
//     <div
//       className="card"
//       style={{ ...appliedStyles.card, ...(isHovered ? appliedStyles.cardHover : {}) }}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <LazyLoadImage src={icon} alt="Work" style={appliedStyles.icon} />
//     </div>
//   );
// };

// const HowItWorksSection = () => {
//   const [width] = useWindowSize();
//   const isMobile = width < 768;
//   const appliedStyles = styles(isMobile);

//   return (
//     <div style={appliedStyles.howItWorksSection}>
//       <h2 style={appliedStyles.title}>1:1 Scale Immersive Experience on Apple Vision Pro</h2>
//       <div className="cardsContainer" style={appliedStyles.cardsContainer}>
//         <WorkCard icon={avpclick} />
//         <WorkCard icon={_741gif} />
//         <WorkCard icon={extgif} />
//         <WorkCard icon={loungegif} />
//       </div>
//     </div>
//   );
// };

// export default HowItWorksSection;
